import React, { createRef, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, useDataProvider, useGetList, useGetOne, useRefresh } from 'react-admin';
import { useParams, useSearchParams } from 'react-router-dom';
import { CommentType } from 'types/comment';
import { UserType } from 'types/user';

import { useOpenAI } from '@hooks/useOpenAI';
import ChevronDown from '@components/svgs/chevron_down';
import PepperAvatar from '@components/pepper_avatar';
import { UserAvatar } from '@models/users/components/user_avatar';
import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';
import AuthorsModal from '@components/chat/authors_modal';
import CommentBlock from '@components/chat/comment_block';

export const TopicChat = () => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const { createCompletion, isLoading: isSuggestingComment } = useOpenAI();

  const { topicId } = useParams() || {};
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedAuthor, setSelectedAuthor] = useState<UserType>();
  const [authorModalOpen, setAuthorModalOpen] = useState(false);
  const [spyVersion, displaySpyVersion] = useState(false);
  const [isCommentCreating, setIsCommentCreating] = useState(false);
  const commentListRef = createRef<HTMLDivElement>();

  const {
    data: topic,
    isLoading: isTopicLoading,
    refetch: refetchTopic,
  } = useGetOne('topics', {
    id: topicId,
  });
  const { data: topicComments, isLoading: areTopicCommentsLoading } = useGetList(
    'topic_comments',
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'createdAt', order: 'DESC' },
      filter: {
        topicId,
        parentCommentId: searchParams?.get('commentId'),
      },
    }
  );

  const { data: parentComment } = useGetOne(
    'topic_comments',
    {
      id: searchParams.get('commentId'),
    },
    {
      enabled: !!searchParams.get('commentId'),
    }
  );

  const { data: authors, isLoading: areAuthorsLoading } = useGetList('users', {
    pagination: { page: 1, perPage: 200 },
    sort: { field: 'username', order: 'ASC' },
    filter: {
      phoneNumber: null,
      appleUserIdentifier: null,
      googleUserIdentifier: null,
    },
  });

  const { data: chilliTeam, isLoading: isChilliTeamLoading } = useGetList('users', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'username', order: 'ASC' },
    filter: {
      username: [
        'xave',
        'chris_from_chilli',
        'ju_ledrogo',
        'Matisse',
        'solarpunkwithadhd',
      ],
    },
  });

  /**
   *
   * @param commentA
   * @param commentB
   * @returns {number}
   */
  const sortComments = (commentA: CommentType, commentB: CommentType) => {
    if (commentA.createdAt < commentB.createdAt) {
      return -1;
    }
    if (commentA.createdAt > commentB.createdAt) {
      return 1;
    }
    return 0;
  };

  /**
   * Handle creating a comment
   * @returns {Promise<void>}
   */
  const handleCreateComment = async () => {
    const textarea = document.querySelector('textarea') as HTMLTextAreaElement;
    const message = textarea.value.trim();
    if (!message || !selectedAuthor || isCommentCreating) {
      return;
    }
    setIsCommentCreating(true);
    try {
      await dataProvider.createCommentViaChat({
        topicId,
        authorId: selectedAuthor.id,
        parentCommentId: searchParams.get('commentId'),
        message,
      });
      await refetchTopic();
      refresh();
    } finally {
      setSelectedAuthor(undefined);
      setIsCommentCreating(false);
    }
    textarea.value = '';
  };

  /**
   * Write a suggested comment using OpenAI
   */
  const suggestComment = useCallback(async () => {
    const textarea = document.querySelector('textarea') as HTMLTextAreaElement;
    if (!textarea) {
      return;
    }
    try {
      const systemMessage = `
You are an AI assistant helping a user write a reply in a chat conversation.
You must follow the following instructions:
- Given the context of the conversation, write a reply that is relevant and engaging.
- You can ask questions, provide information, or make suggestions to keep the conversation going.
- Always answer using the same language and tone as the conversation.
- Keep the reply short (less than 100 words) and to the point.
`;
      const prompt = `
# Who are you in the conversation?
- username: ${selectedAuthor?.username}
- bio: ${selectedAuthor?.bio?.replace(/\n/gi, ' ') || 'none'}
- location: ${selectedAuthor?.region || 'none'}, ${selectedAuthor?.country || 'none'}
${
  parentComment
    ? `
# Main message was:
- **${parentComment.author.username} wrote:** ${parentComment.messageSanitized}
`
    : ''
}

# Last 20 messages of the conversation:
${topicComments
  ?.filter(comment => comment.messageSanitized)
  .slice(-20)
  .map(
    comment =>
      `- **${comment.author.username} wrote:** ${comment.messageSanitized.replace(/\n/g, ' ')}`
  )
  .join('\n')}
`;
      const response = await createCompletion({ systemMessage, prompt });
      if (response) {
        textarea.value = response;
      }
    } catch (error: any) {
      console.error('[TopicChat.suggestComment]', error);
    }
  }, [topicComments, selectedAuthor, parentComment, createCompletion]);

  const isInThread = useMemo(() => !!searchParams.get('commentId'), [searchParams]);

  useEffect(() => {
    if (topicComments && topicComments.length && commentListRef?.current) {
      commentListRef.current.scrollTop = commentListRef.current.scrollHeight;
    }
  }, [topicComments, commentListRef]);

  if (
    areTopicCommentsLoading ||
    isTopicLoading ||
    areAuthorsLoading ||
    isChilliTeamLoading
  ) {
    return;
  }

  if (!topic) {
    return <div>Topic not found</div>;
  }

  return (
    <div
      style={{
        ...styles.wrapper,
        ...(spyVersion ? { backgroundColor: Colors.Grey.primary } : null),
      }}
    >
      <AuthorsModal
        authors={[...(chilliTeam || []), ...(authors || [])]}
        open={authorModalOpen}
        onClose={() => setAuthorModalOpen(false)}
        onSelect={(author: UserType) => {
          setSelectedAuthor(author);
          setAuthorModalOpen(false);
        }}
      />
      <div style={styles.container}>
        <div style={styles.header}>
          <div>
            {isInThread ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: SpacingStyle[4],
                  cursor: 'pointer',
                }}
                onClick={() => setSearchParams()}
              >
                <ChevronDown
                  style={{ transform: 'rotate(90deg)' }}
                  width={18}
                  height={18}
                  strokeWidth={1.5}
                />
                <div style={styles.headerTopicName}>Back</div>
              </div>
            ) : (
              <>
                <div style={styles.headerTopicName}>{topic.nameEN}</div>
                <div style={styles.headerTopicMembers}>
                  {(topic.users || []).length} members
                </div>
              </>
            )}
          </div>
          <label style={{ display: 'flex', flexDirection: 'row' }}>
            <input
              type="checkbox"
              onChange={event => displaySpyVersion(!!event.target.checked)}
            />
            🕵️
          </label>
        </div>
        <div style={styles.commentList} ref={commentListRef}>
          {!parentComment && topicComments?.length === 0 && (
            <div style={styles.commentContainer}>
              No comments yet. Be the first one to comment!
            </div>
          )}
          {parentComment && (
            <CommentBlock
              comment={parentComment}
              isParentComment
              isInThread={isInThread}
              spyVersion={spyVersion}
              setSearchParams={setSearchParams}
              commentResourceType="topic_comments"
            />
          )}
          {(topicComments || []).sort(sortComments).map((comment: CommentType) => (
            <CommentBlock
              key={comment.id}
              comment={comment}
              isInThread={isInThread}
              spyVersion={spyVersion}
              setSearchParams={setSearchParams}
              commentResourceType="topic_comments"
            />
          ))}
        </div>
        {!spyVersion && (
          <div style={styles.inputContainer}>
            <div
              style={styles.inputAuthorButton}
              onClick={() => setAuthorModalOpen(true)}
            >
              {selectedAuthor ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: SpacingStyle.small,
                  }}
                >
                  <UserAvatar record={selectedAuthor} width={18} height={18} />
                  {selectedAuthor.username}
                </div>
              ) : (
                'Select an author'
              )}
              <ChevronDown width={18} height={18} stroke={Colors.Grey.primary} />
            </div>
            <textarea
              style={styles.inputTextarea}
              rows={8}
              placeholder={isInThread ? 'Reply to thread' : 'Write a comment'}
              disabled={!selectedAuthor || isCommentCreating || isSuggestingComment}
            />
            <div style={styles.inputFooter}>
              <Button
                label="Suggest a comment"
                startIcon={<PepperAvatar />}
                onClick={suggestComment}
                disabled={!selectedAuthor || isCommentCreating || isSuggestingComment}
                variant="outlined"
              />
              <Button
                disabled={!selectedAuthor || isCommentCreating || isSuggestingComment}
                onClick={handleCreateComment}
                label={isInThread ? 'Reply' : 'Comment'}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const styles: any = {
  wrapper: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: '94vh',
    width: '100%',
    maxWidth: '600px',
    backgroundColor: Colors.White.primary,
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.05)',
    borderRadius: BorderStyle.Radius.normal,
    border: `1px solid ${Colors.Grey[100]}`,
    overflow: 'hidden',
  },

  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: SpacingStyle.normal,
    borderBottom: `1px solid ${Colors.Grey[50]}`,
    backgroundColor: Colors.White.primary,
  },
  headerTopicName: {
    fontSize: FontStyle.sizeNormal,
    fontWeight: 700,
  },
  headerTopicMembers: {
    fontSize: FontStyle.sizeVerySmall,
    color: Colors.Grey[600],
  },

  commentList: {
    flexGrow: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  commentContainer: {
    padding: `${SpacingStyle.normal}px ${SpacingStyle.normal}px`,
    backgroundColor: Colors.White.primary,
    borderBottom: `1px solid ${Colors.Grey[50]}`,
  },

  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: SpacingStyle.small,
    padding: SpacingStyle.small,
    borderTop: `1px solid ${Colors.Grey[100]}`,
    backgroundColor: Colors.OffWhite.primary,
  },
  inputTextarea: {
    width: '100%',
    padding: SpacingStyle.normal,
    borderRadius: BorderStyle.Radius.normal,
    border: `1px solid ${Colors.Grey[100]}`,
    fontFamily: 'inherit',
    fontSize: FontStyle.sizeMedium,
    resize: 'none',
  },
  inputAuthorButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${SpacingStyle.small}px ${SpacingStyle.normal}px`,
    minWidth: 100,
    fontSize: FontStyle.sizeSmall,
    fontWeight: 600,
    color: Colors.OffBlack.primary,
    borderRadius: BorderStyle.Radius.normal,
    backgroundColor: Colors.White.primary,
    border: `1px solid ${Colors.Grey[200]}`,
    cursor: 'pointer',
  },
  inputFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  inputSendButton: {
    padding: `${SpacingStyle.small}px ${SpacingStyle.normal}px`,
    fontSize: FontStyle.sizeSmall,
    fontWeight: 700,
    color: Colors.White.primary,
    borderRadius: BorderStyle.Radius.normal,
    backgroundColor: Colors.OffBlack.primary,
    cursor: 'pointer',
  },
};
