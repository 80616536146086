import React from 'react';
import {
  AdminContext,
  AdminUI,
  CustomRoutes,
  defaultI18nProvider,
  localStorageStore,
  Resource,
  ResourceProps,
  useGetIdentity,
  usePermissions,
} from 'react-admin';
import { Route } from 'react-router-dom';
import { ActionCTAEmailType, ActionCTAPhoneCallType, ActionType } from 'types/action';
import { CauseType } from 'types/cause';
import { CRMOrganizationType } from 'types/crm_organization';
import { CRMPersonType } from 'types/crm_person';
import { CRMTagType } from 'types/crm_tag';
import { DonationType } from 'types/donation';
import { CampaignType } from 'types/campaign';
import { CommentType } from 'types/comment';
import { Deal } from 'types/deal';
import { PepperPillType } from 'types/pepper_pill';
import { ProfilePromptType, ProfileSkillType, ProfileTagType } from 'types/profile';
import { VideoType } from 'types/video';
import { CausePostType } from 'types/cause_post';

import { useReloadPage } from '@hooks/useReloadPage';
import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import ellipsis from '@services/ellipsis';
import { dateFormatter } from '@services/date';
import queryClient from '@modules/query_client';
import Reloading from '@containers/reloading';
import QRCodeGenerator from '@containers/qr_code_generator';
import LoginPage from '@containers/login';
import Loading from '@containers/loading';
import DevBanner from '@containers/dev_banner';
import { ActionList } from '@models/actions/list';
import { ActionShow } from '@models/actions/show';
import { ActionCreate } from '@models/actions/create';
import { ActionEdit } from '@models/actions/edit';
import { ActionOwnerList } from '@models/actions_owners/list';
import { ActionOwnerShow } from '@models/actions_owners/show';
import { ActionOwnerCreate } from '@models/actions_owners/create';
import { ActionOwnerEdit } from '@models/actions_owners/edit';
import { ActionCtaAppPageList } from '@models/action_ctas/app_page/list';
import { ActionCtaAppPageShow } from '@models/action_ctas/app_page/show';
import { ActionCtaAppPageCreate } from '@models/action_ctas/app_page/create';
import { ActionCtaAppPageEdit } from '@models/action_ctas/app_page/edit';
import { ActionCtaEmailList } from '@models/action_ctas/emails/list';
import { ActionCtaEmailShow } from '@models/action_ctas/emails/show';
import { ActionCtaEmailCreate } from '@models/action_ctas/emails/create';
import { ActionCtaEmailEdit } from '@models/action_ctas/emails/edit';
import { ActionCtaExternalLinkList } from '@models/action_ctas/external_links/list';
import { ActionCtaExternalLinkShow } from '@models/action_ctas/external_links/show';
import { ActionCtaExternalLinkCreate } from '@models/action_ctas/external_links/create';
import { ActionCtaExternalLinkEdit } from '@models/action_ctas/external_links/edit';
import { ActionCtaGoogleMapsReviewList } from '@models/action_ctas/googlemaps_reviews/list';
import { ActionCtaGoogleMapsReviewShow } from '@models/action_ctas/googlemaps_reviews/show';
import { ActionCtaGoogleMapsReviewCreate } from '@models/action_ctas/googlemaps_reviews/create';
import { ActionCtaGoogleMapsReviewEdit } from '@models/action_ctas/googlemaps_reviews/edit';
import { ActionCtaPhoneCallList } from '@models/action_ctas/phone_calls/list';
import { ActionCtaPhoneCallShow } from '@models/action_ctas/phone_calls/show';
import { ActionCtaPhoneCallCreate } from '@models/action_ctas/phone_calls/create';
import { ActionCtaPhoneCallEdit } from '@models/action_ctas/phone_calls/edit';
import { ActionCtaShareLinkList } from '@models/action_ctas/share_links/list';
import { ActionCtaShareLinkShow } from '@models/action_ctas/share_links/show';
import { ActionCtaShareLinkCreate } from '@models/action_ctas/share_links/create';
import { ActionCtaShareLinkEdit } from '@models/action_ctas/share_links/edit';
import { ActionCtaSocialCommentList } from '@models/action_ctas/social_comments/list';
import { ActionCtaSocialCommentShow } from '@models/action_ctas/social_comments/show';
import { ActionCtaSocialCommentCreate } from '@models/action_ctas/social_comments/create';
import { ActionCtaSocialCommentEdit } from '@models/action_ctas/social_comments/edit';
import { ActionCtaSocialPostList } from '@models/action_ctas/social_posts/list';
import { ActionCtaSocialPostShow } from '@models/action_ctas/social_posts/show';
import { ActionCtaSocialPostCreate } from '@models/action_ctas/social_posts/create';
import { ActionCtaSocialPostEdit } from '@models/action_ctas/social_posts/edit';
import { ActionCtaWebpageList } from '@models/action_ctas/webpages/list';
import { ActionCtaWebpageShow } from '@models/action_ctas/webpages/show';
import { ActionCtaWebpageCreate } from '@models/action_ctas/webpages/create';
import { ActionCtaWebpageEdit } from '@models/action_ctas/webpages/edit';
import { BadgeList } from '@models/badges/list';
import { BadgeShow } from '@models/badges/show';
import { BadgeCreate } from '@models/badges/create';
import { BadgeEdit } from '@models/badges/edit';
import BadgeRecordRepresentation from '@models/badges/components/record_representation';
import { CampaignList } from '@models/campaigns/list';
import { CampaignShow } from '@models/campaigns/show';
import { CampaignCreate } from '@models/campaigns/create';
import { CampaignEdit } from '@models/campaigns/edit';
import CampaignsAsInOnboarding from '@models/campaigns/components/campaigns_as_in_onboarding';
import { CampaignCauseList } from '@models/campaigns_causes/list';
import { CampaignCauseShow } from '@models/campaigns_causes/show';
import { CampaignCauseCreate } from '@models/campaigns_causes/create';
import { CampaignOwnerList } from '@models/campaigns_owners/list';
import { CampaignOwnerShow } from '@models/campaigns_owners/show';
import { CampaignOwnerCreate } from '@models/campaigns_owners/create';
import { CampaignOwnerEdit } from '@models/campaigns_owners/edit';
import { CauseList } from '@models/causes/list';
import { CauseCreate } from '@models/causes/create';
import { CauseEdit } from '@models/causes/edit';
import { CauseShow } from '@models/causes/show';
import { CausePostChat } from '@models/causes_posts/components/show/chat';
import CRMIndex from '@models/crm/index';
import { CRMOrganizationList } from '@models/crm/crm_organizations/list';
import { CRMOrganizationShow } from '@models/crm/crm_organizations/show';
import { CRMOrganizationEdit } from '@models/crm/crm_organizations/edit';
import { CRMPersonList } from '@models/crm/crm_persons/list';
import { CRMPersonShow } from '@models/crm/crm_persons/show';
import { CRMPersonEdit } from '@models/crm/crm_persons/edit';
import { CRMTagList } from '@models/crm/crm_tags/list';
import { CRMTagShow } from '@models/crm/crm_tags/show';
import { CRMTagCreate } from '@models/crm/crm_tags/create';
import { CRMTagEdit } from '@models/crm/crm_tags/edit';
import Dashboard from '@models/dashboard';
import { DonationList } from '@models/donations/list';
import { DonationShow } from '@models/donations/show';
import { DonationCreate } from '@models/donations/create';
import { DonationEdit } from '@models/donations/edit';
import { DealList } from '@models/deals/list';
import { DealShow } from '@models/deals/show';
import { DealCreate } from '@models/deals/create';
import { DealEdit } from '@models/deals/edit';
import { DealCouponList } from '@models/deals_coupons/list';
import { DealCouponShow } from '@models/deals_coupons/show';
import { DealCouponCreate } from '@models/deals_coupons/create';
import { DealCouponEdit } from '@models/deals_coupons/edit';
import { FeatureFlagList } from '@models/feature_flags/list';
import { FeatureFlagCreate } from '@models/feature_flags/create';
import { FeatureFlagEdit } from '@models/feature_flags/edit';
import { FeatureFlagShow } from '@models/feature_flags/show';
import { InAppNotificationList } from '@models/in_app_notifications/list';
import { InAppNotificationShow } from '@models/in_app_notifications/show';
import { InAppNotificationCreate } from '@models/in_app_notifications/create';
import { InAppNotificationEdit } from '@models/in_app_notifications/edit';
import { PepperPillList } from '@models/pepper_pills/list';
import { PepperPillShow } from '@models/pepper_pills/show';
import { PepperPillCreate } from '@models/pepper_pills/create';
import { PepperPillEdit } from '@models/pepper_pills/edit';
import { SubtitleList } from '@models/subtitles/list';
import { SubtitleShow } from '@models/subtitles/show';
import { SubtitleCreate } from '@models/subtitles/create';
import { SubtitleEdit } from '@models/subtitles/edit';
import { TopicList } from '@models/topics/list';
import { TopicCreate } from '@models/topics/create';
import { TopicEdit } from '@models/topics/edit';
import { TopicShow } from '@models/topics/show';
import { TopicChat } from '@models/topics/components/chat';
import { TopicCommentList } from '@models/topic_comments/list';
import { TopicCommentCreate } from '@models/topic_comments/create';
import { TopicCommentEdit } from '@models/topic_comments/edit';
import { TopicCommentShow } from '@models/topic_comments/show';
import { UserList } from '@models/users/list';
import { UserShow } from '@models/users/show';
import { UserCreate } from '@models/users/create';
import { UserEdit } from '@models/users/edit';
import { UserBadgeList } from '@models/users_badges/list';
import { UserBadgeShow } from '@models/users_badges/show';
import { UserBadgeCreate } from '@models/users_badges/create';
import { UserBadgeEdit } from '@models/users_badges/edit';
import { UserCampaignList } from '@models/users_campaigns/list';
import { UserCampaignCreate } from '@models/users_campaigns/create';
import { UserCampaignShow } from '@models/users_campaigns/show';
import { UsersFeatureFlagsList } from '@models/users_feature_flags/list';
import { UsersFeatureFlagsShow } from '@models/users_feature_flags/show';
import { UsersFeatureFlagsCreate } from '@models/users_feature_flags/create';
import { UsersFeatureFlagsEdit } from '@models/users_feature_flags/edit';
import { UsersPointsList } from '@models/users_points/list';
import { UsersPointsShow } from '@models/users_points/show';
import { UsersPointsCreate } from '@models/users_points/create';
import { UsersPointsEdit } from '@models/users_points/edit';
import { UsersTopicsList } from '@models/users_topics/list';
import { UsersTopicsShow } from '@models/users_topics/show';
import { UsersTopicsCreate } from '@models/users_topics/create';
import { UsersTopicsEdit } from '@models/users_topics/edit';
import { UserProfilePromptList } from '@models/users_profile_prompts/list';
import { UserProfilePromptShow } from '@models/users_profile_prompts/show';
import { UserProfilePromptCreate } from '@models/users_profile_prompts/create';
import { UserProfilePromptEdit } from '@models/users_profile_prompts/edit';
import { UserProfileSkillList } from '@models/users_profile_skills/list';
import { UserProfileSkillShow } from '@models/users_profile_skills/show';
import { UserProfileSkillCreate } from '@models/users_profile_skills/create';
import { UserProfileSkillEdit } from '@models/users_profile_skills/edit';
import { UserProfileTagList } from '@models/users_profile_tags/list';
import { UserProfileTagShow } from '@models/users_profile_tags/show';
import { UserProfileTagCreate } from '@models/users_profile_tags/create';
import { UserProfileTagEdit } from '@models/users_profile_tags/edit';
import { UserProfileMediaList } from '@models/users_profile_medias/list';
import { UserProfileMediaShow } from '@models/users_profile_medias/show';
import { UserProfileMediaEdit } from '@models/users_profile_medias/edit';
import { VideoList } from '@models/videos/list';
import { VideoShow } from '@models/videos/show';
import { VideoCreate } from '@models/videos/create';
import { VideoEdit } from '@models/videos/edit';
import CRMPersonsImport from '@models/crm/crm_persons/import';
import UserRecordRepresentation from '@models/users/components/record_representation';
import TopicRecordRepresentation from '@models/topics/components/record_representation';
import { TopicHighlightList } from '@models/topic_highlights/list';
import { TopicHighlightShow } from '@models/topic_highlights/show';
import { TopicHighlightEdit } from '@models/topic_highlights/edit';
import { ProfileSkillList } from '@models/profile_skills/list';
import { ProfileSkillShow } from '@models/profile_skills/show';
import { ProfileSkillCreate } from '@models/profile_skills/create';
import { ProfileSkillEdit } from '@models/profile_skills/edit';
import { ProfileTagCreate } from '@models/profile_tags/create';
import { ProfileTagEdit } from '@models/profile_tags/edit';
import { ProfileTagList } from '@models/profile_tags/list';
import { ProfileTagShow } from '@models/profile_tags/show';
import { ProfilePromptList } from '@models/profile_prompts/list';
import { ProfilePromptShow } from '@models/profile_prompts/show';
import { ProfilePromptCreate } from '@models/profile_prompts/create';
import { ProfilePromptEdit } from '@models/profile_prompts/edit';
import { DonationRecipientList } from '@models/donations_recipients/list';
import { DonationRecipientShow } from '@models/donations_recipients/show';
import { DonationRecipientCreate } from '@models/donations_recipients/create';
import { DonationRecipientEdit } from '@models/donations_recipients/edit';
import { UsersTransactionsCreate } from '@models/users_transactions/create';
import { UserTransactionsEdit } from '@models/users_transactions/edit';
import { UsersTransactionsList } from '@models/users_transactions/list';
import { UsersTransactionsShow } from '@models/users_transactions/show';
import ShopIndex from '@models/shop';
import { CausePostList } from '@models/causes_posts/list';
import { CausePostCreate } from '@models/causes_posts/create';
import { CausePostShow } from '@models/causes_posts/show';
import { CausePostEdit } from '@models/causes_posts/edit';
import { CausesPostsCommentList } from '@models/causes_posts_comments/list';
import { CausesPostsCommentCreate } from '@models/causes_posts_comments/create';
import { CausesPostsCommentShow } from '@models/causes_posts_comments/show';
import { CausesPostsCommentEdit } from '@models/causes_posts_comments/edit';
import ActionBuilder from '@models/actions/builder';

import { CustomLayout } from './layout';
import theme from './theme';
import authProvider from './authProvider';
import dataProvider from './dataProvider';

const store = localStorageStore();

type CustomResourceProps = {
  tableName: string;
  list?: ResourceProps['list'];
  show?: ResourceProps['show'];
  create?: ResourceProps['create'];
  edit?: ResourceProps['edit'];
  recordRepresentation?: ResourceProps['recordRepresentation'];
  subRoutes?: React.JSX.Element[];
};
const ResourcesAvailable: CustomResourceProps[] = [
  {
    tableName: 'actions',
    list: ActionList,
    show: ActionShow,
    create: ActionCreate,
    edit: ActionEdit,
    recordRepresentation: (action: ActionType) => {
      const actionPublishedAt = action.publishedAt
        ? dateFormatter(action.publishedAt, { short: true })
        : '(draft)';
      const actionName = ellipsis(
        action.name || action.descriptionEN?.slice(0, 30).trim() || 'No name',
        20
      );
      const actionType = action.ctaType || 'nothing';
      return `[${actionType}] ${actionName} ${actionPublishedAt}`.trim();
    },
    subRoutes: [<Route path=":id/builder" element={<ActionBuilder />} />],
  },
  {
    tableName: 'action_cta_app_pages',
    list: ActionCtaAppPageList,
    show: ActionCtaAppPageShow,
    create: ActionCtaAppPageCreate,
    edit: ActionCtaAppPageEdit,
  },
  {
    tableName: 'action_cta_emails',
    list: ActionCtaEmailList,
    show: ActionCtaEmailShow,
    create: ActionCtaEmailCreate,
    edit: ActionCtaEmailEdit,
    recordRepresentation: (cta: ActionCTAEmailType) =>
      ellipsis(cta?.subject, 30) || 'New email',
  },
  {
    tableName: 'action_cta_external_links',
    list: ActionCtaExternalLinkList,
    show: ActionCtaExternalLinkShow,
    create: ActionCtaExternalLinkCreate,
    edit: ActionCtaExternalLinkEdit,
  },
  {
    tableName: 'action_cta_googlemaps_reviews',
    list: ActionCtaGoogleMapsReviewList,
    show: ActionCtaGoogleMapsReviewShow,
    create: ActionCtaGoogleMapsReviewCreate,
    edit: ActionCtaGoogleMapsReviewEdit,
  },
  {
    tableName: 'action_cta_phone_calls',
    list: ActionCtaPhoneCallList,
    show: ActionCtaPhoneCallShow,
    create: ActionCtaPhoneCallCreate,
    edit: ActionCtaPhoneCallEdit,
    recordRepresentation: (cta: ActionCTAPhoneCallType) =>
      cta && cta.phoneNumber && cta.script
        ? `[${cta?.phoneNumber}] ${ellipsis(cta.script, 10)}`
        : 'New phone call',
  },
  {
    tableName: 'action_cta_share_links',
    list: ActionCtaShareLinkList,
    show: ActionCtaShareLinkShow,
    create: ActionCtaShareLinkCreate,
    edit: ActionCtaShareLinkEdit,
  },
  {
    tableName: 'action_cta_social_comments',
    list: ActionCtaSocialCommentList,
    show: ActionCtaSocialCommentShow,
    create: ActionCtaSocialCommentCreate,
    edit: ActionCtaSocialCommentEdit,
  },
  {
    tableName: 'action_cta_social_posts',
    list: ActionCtaSocialPostList,
    show: ActionCtaSocialPostShow,
    create: ActionCtaSocialPostCreate,
    edit: ActionCtaSocialPostEdit,
  },
  {
    tableName: 'action_cta_webpages',
    list: ActionCtaWebpageList,
    show: ActionCtaWebpageShow,
    create: ActionCtaWebpageCreate,
    edit: ActionCtaWebpageEdit,
  },
  {
    tableName: 'actions_owners',
    list: ActionOwnerList,
    show: ActionOwnerShow,
    create: ActionOwnerCreate,
    edit: ActionOwnerEdit,
  },
  {
    tableName: 'badges',
    list: BadgeList,
    show: BadgeShow,
    create: BadgeCreate,
    edit: BadgeEdit,
    recordRepresentation: <BadgeRecordRepresentation />,
  },
  {
    tableName: 'campaigns',
    list: CampaignList,
    show: CampaignShow,
    create: CampaignCreate,
    edit: CampaignEdit,
    recordRepresentation: (c: CampaignType) => c.name,
    subRoutes: [<Route path="/onboarding" element={<CampaignsAsInOnboarding />} />],
  },
  {
    tableName: 'campaigns_causes',
    list: CampaignCauseList,
    show: CampaignCauseShow,
    create: CampaignCauseCreate,
  },
  {
    tableName: 'campaigns_owners',
    list: CampaignOwnerList,
    show: CampaignOwnerShow,
    create: CampaignOwnerCreate,
    edit: CampaignOwnerEdit,
  },
  {
    tableName: 'causes',
    list: CauseList,
    show: CauseShow,
    create: CauseCreate,
    edit: CauseEdit,
    recordRepresentation: (cause: CauseType) => `${cause.emoji} ${cause.name}`,
  },
  {
    tableName: 'causes_posts',
    list: CausePostList,
    show: CausePostShow,
    create: CausePostCreate,
    edit: CausePostEdit,
    recordRepresentation: (causePost: CausePostType) => causePost.titleEN,
    subRoutes: [<Route path=":causePostId/chat" element={<CausePostChat />} />],
  },
  {
    tableName: 'causes_posts_comments',
    list: CausesPostsCommentList,
    show: CausesPostsCommentShow,
    create: CausesPostsCommentCreate,
    edit: CausesPostsCommentEdit,
    recordRepresentation: (comment: CommentType) =>
      ellipsis(comment.message) || comment.id,
  },
  {
    tableName: 'crm_organizations',
    list: CRMOrganizationList,
    show: CRMOrganizationShow,
    edit: CRMOrganizationEdit,
    recordRepresentation: (org: CRMOrganizationType) =>
      `${org.name || ''}`.trim() || org.email || 'No name',
  },
  {
    tableName: 'crm_persons',
    list: CRMPersonList,
    show: CRMPersonShow,
    edit: CRMPersonEdit,
    recordRepresentation: (person: CRMPersonType) =>
      `${person.firstname || ''} ${person.lastname || ''}`.trim() ||
      person.email ||
      'No name',
    subRoutes: [<Route path="/import" element={<CRMPersonsImport />} />],
  },
  {
    tableName: 'crm_tags',
    list: CRMTagList,
    show: CRMTagShow,
    create: CRMTagCreate,
    edit: CRMTagEdit,
    recordRepresentation: (tag: CRMTagType) => tag.name,
  },
  {
    tableName: 'deals',
    list: DealList,
    show: DealShow,
    create: DealCreate,
    edit: DealEdit,
    recordRepresentation: (deal: Deal) => `${deal.nameEN}`,
  },
  {
    tableName: 'deals_coupons',
    list: DealCouponList,
    show: DealCouponShow,
    create: DealCouponCreate,
    edit: DealCouponEdit,
  },
  {
    tableName: 'donations',
    list: DonationList,
    show: DonationShow,
    create: DonationCreate,
    edit: DonationEdit,
    recordRepresentation: (donation: DonationType) => `${donation.nameEN}`,
  },
  {
    tableName: 'donations_recipients',
    list: DonationRecipientList,
    show: DonationRecipientShow,
    create: DonationRecipientCreate,
    edit: DonationRecipientEdit,
  },
  {
    tableName: 'feature_flags',
    list: FeatureFlagList,
    show: FeatureFlagShow,
    create: FeatureFlagCreate,
    edit: FeatureFlagEdit,
    recordRepresentation: ff => ff.name,
  },
  {
    tableName: 'in_app_notifications',
    list: InAppNotificationList,
    show: InAppNotificationShow,
    create: InAppNotificationCreate,
    edit: InAppNotificationEdit,
    recordRepresentation: notif => notif.titleEN,
  },
  {
    tableName: 'pepper_pills',
    list: PepperPillList,
    show: PepperPillShow,
    create: PepperPillCreate,
    edit: PepperPillEdit,
    recordRepresentation: (pill: PepperPillType) => ellipsis(pill.titleEN, 30) || '',
  },
  {
    tableName: 'profile_skills',
    list: ProfileSkillList,
    show: ProfileSkillShow,
    create: ProfileSkillCreate,
    edit: ProfileSkillEdit,
    recordRepresentation: (profileSkill: ProfileSkillType) =>
      `${profileSkill.emoji} ${profileSkill.labelEN}`,
  },
  {
    tableName: 'profile_tags',
    list: ProfileTagList,
    show: ProfileTagShow,
    create: ProfileTagCreate,
    edit: ProfileTagEdit,
    recordRepresentation: (profileTag: ProfileTagType) =>
      `${profileTag.emoji} ${profileTag.labelEN}`,
  },
  {
    tableName: 'profile_prompts',
    list: ProfilePromptList,
    show: ProfilePromptShow,
    create: ProfilePromptCreate,
    edit: ProfilePromptEdit,
    recordRepresentation: (profilePrompt: ProfilePromptType) =>
      `${profilePrompt.questionEN.slice(0, 30)}`,
  },
  {
    tableName: 'subtitles',
    list: SubtitleList,
    show: SubtitleShow,
    create: SubtitleCreate,
    edit: SubtitleEdit,
  },
  {
    tableName: 'topics',
    list: TopicList,
    show: TopicShow,
    create: TopicCreate,
    edit: TopicEdit,
    recordRepresentation: <TopicRecordRepresentation />,
    subRoutes: [<Route path=":topicId/chat" element={<TopicChat />} />],
  },
  {
    tableName: 'topic_comments',
    list: TopicCommentList,
    show: TopicCommentShow,
    create: TopicCommentCreate,
    edit: TopicCommentEdit,
    recordRepresentation: (tc: CommentType) =>
      ellipsis(tc.message || tc.silentMessageEN, 30) || tc.id,
  },
  {
    tableName: 'topic_highlights',
    list: TopicHighlightList,
    show: TopicHighlightShow,
    edit: TopicHighlightEdit,
  },
  {
    tableName: 'users',
    list: UserList,
    show: UserShow,
    create: UserCreate,
    edit: UserEdit,
    recordRepresentation: <UserRecordRepresentation />,
  },
  {
    tableName: 'users_feature_flags',
    list: UsersFeatureFlagsList,
    show: UsersFeatureFlagsShow,
    create: UsersFeatureFlagsCreate,
    edit: UsersFeatureFlagsEdit,
  },
  {
    tableName: 'users_points',
    list: UsersPointsList,
    show: UsersPointsShow,
    create: UsersPointsCreate,
    edit: UsersPointsEdit,
  },
  {
    tableName: 'users_transactions',
    list: UsersTransactionsList,
    show: UsersTransactionsShow,
    create: UsersTransactionsCreate,
    edit: UserTransactionsEdit,
  },
  {
    tableName: 'users_profile_medias',
    list: UserProfileMediaList,
    show: UserProfileMediaShow,
    edit: UserProfileMediaEdit,
  },
  {
    tableName: 'users_profile_skills',
    list: UserProfileSkillList,
    show: UserProfileSkillShow,
    create: UserProfileSkillCreate,
    edit: UserProfileSkillEdit,
  },
  {
    tableName: 'users_profile_prompts',
    list: UserProfilePromptList,
    show: UserProfilePromptShow,
    create: UserProfilePromptCreate,
    edit: UserProfilePromptEdit,
  },
  {
    tableName: 'users_profile_tags',
    list: UserProfileTagList,
    show: UserProfileTagShow,
    create: UserProfileTagCreate,
    edit: UserProfileTagEdit,
  },
  {
    tableName: 'users_topics',
    list: UsersTopicsList,
    show: UsersTopicsShow,
    create: UsersTopicsCreate,
    edit: UsersTopicsEdit,
  },
  {
    tableName: 'users_badges',
    list: UserBadgeList,
    show: UserBadgeShow,
    create: UserBadgeCreate,
    edit: UserBadgeEdit,
  },
  {
    tableName: 'users_campaigns',
    list: UserCampaignList,
    show: UserCampaignShow,
    create: UserCampaignCreate,
  },
  {
    tableName: 'videos',
    list: VideoList,
    show: VideoShow,
    create: VideoCreate,
    edit: VideoEdit,
    recordRepresentation: (video: VideoType) => video.name,
  },
];

const Resources = () => {
  const { canRead, canCreate, canUpdate } = useRoleBasedPermissions();
  const { isLoading: arePermissionsLoading } = usePermissions();
  const { isLoading: isIdentityLoading } = useGetIdentity();

  return (
    <AdminUI
      disableTelemetry
      requireAuth
      layout={CustomLayout}
      loginPage={LoginPage}
      dashboard={Dashboard}
    >
      {arePermissionsLoading && isIdentityLoading && <Loading />}
      {ResourcesAvailable.map((resource, index) => (
        <Resource
          key={`${resource.tableName}-${index}`}
          name={resource.tableName}
          list={resource.list}
          show={resource.show}
          hasShow={canRead(resource.tableName)}
          create={resource.create}
          hasCreate={canCreate(resource.tableName)}
          edit={resource.edit}
          hasEdit={canUpdate(resource.tableName)}
          recordRepresentation={resource.recordRepresentation}
        >
          {resource?.subRoutes?.map((subRoute, index) => (
            <subRoute.type
              key={`${resource.tableName}-subroute-${index}`}
              {...subRoute.props}
            />
          ))}
        </Resource>
      ))}

      <CustomRoutes>
        <Route path="/shop" element={<ShopIndex />} />
        <Route path="/crm" element={<CRMIndex />} />
        <Route path="/qr_code_generator" element={<QRCodeGenerator />} />
      </CustomRoutes>
    </AdminUI>
  );
};

const App = () => {
  const isReloading = useReloadPage();

  if (isReloading) {
    return <Reloading />;
  }

  return (
    <>
      <DevBanner />
      <AdminContext
        theme={theme}
        dataProvider={dataProvider}
        authProvider={authProvider}
        queryClient={queryClient}
        store={store}
        i18nProvider={defaultI18nProvider}
      >
        <Resources />
      </AdminContext>
    </>
  );
};

export default App;
