import { fetchUtils } from 'react-admin';
import queryString from 'query-string';

import { OpenAICompletion, OpenAIStructuredCompletion } from '@hooks/useOpenAI';

import Config from './config';

const apiUrl = Config.API_URL;

export const httpClient = (url: string, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('authToken');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = {
  getList: (resource: string, params: any) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter),
    };
    const url = `${apiUrl}/${resource}?${queryString.stringify(query)}`;

    return httpClient(url).then(({ json }) => ({
      data: json.data,
      pageInfo: {
        hasNextPage: json._metadata.pageSize < json._metadata.total,
      },
      total: json._metadata.total,
    }));
  },

  getOne: (resource: string, params: any) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: json.data,
    })),

  getMany: (resource: string, params: any) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${queryString.stringify(query)}`;
    return httpClient(url).then(({ json }) => ({ data: json.data }));
  },

  // TODO: to be implemented in server
  getManyReference: (resource: string, params: any) => {
    console.warn('[getManyReference] NOT IMPLEMENTED');
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${queryString.stringify(query)}`;

    return httpClient(url).then(({ headers, json }: any) => ({
      data: json,
      total: parseInt(headers.get('content-range').split('/').pop(), 10),
    }));
  },

  update: async (resource: string, params: any) => {
    let body = params.data;
    if (!!params.data.picture && params.data.picture.rawFile instanceof File) {
      const base64pic = await convertFileToBase64(params.data.picture);
      body.picture = base64pic;
    }

    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(body),
    }).then(({ json }) => ({ data: json.data }));
  },

  // TODO
  updateMany: (_resource: string, _params: any) => {
    throw new Error('NOT IMPLEMENTED');
  },

  create: async (resource: string, params: any) => {
    let body = params.data;
    if (!!params.data.picture && params.data.picture.rawFile instanceof File) {
      const base64pic = await convertFileToBase64(params.data.picture);
      body.picture = base64pic;
    }

    return httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, ...json.data, id: json.id || json.data?.id },
    }));
  },

  getCategories: (resource: string) => {
    return httpClient(`${apiUrl}/${resource}_categories`, {
      method: 'GET',
    }).then(({ json }) => ({ data: json.data }));
  },

  delete: (resource: string, params: any) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json.data })),

  // TODO
  deleteMany: (resource: string, params: any) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${queryString.stringify(query)}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json.data }));
  },

  deleteJunctionRecord: (
    resource: string,
    leftResource: string,
    leftId: string,
    rightResource: string,
    rightId: string
  ) => {
    return httpClient(
      `${apiUrl}/${resource}/${leftResource}/${leftId}/${rightResource}/${rightId}`,
      {
        method: 'DELETE',
      }
    ).then(({ json }) => ({ data: json.data }));
  },

  sendUserPushNotification: ({ userId, pushNotificationBody }: any) =>
    httpClient(`${apiUrl}/users/${userId}/push_notification`, {
      method: 'POST',
      body: JSON.stringify({ pushNotificationBody }),
    }).then(({ json }) => ({ data: json.data })),

  addFFToBetaUsers: ({ featureFlagId }: any) =>
    httpClient(`${apiUrl}/feature_flags/${featureFlagId}/beta_users`, {
      method: 'POST',
    }).then(({ json }) => ({ data: json.data })),
  removeFFFromBetaUsers: ({ featureFlagId }: any) =>
    httpClient(`${apiUrl}/feature_flags/${featureFlagId}/beta_users`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json.data })),
  getS3PresignedURL: ({ filekey }: any) =>
    httpClient(`${apiUrl}/aws/sign-s3`, {
      method: 'POST',
      body: JSON.stringify({ filekey }),
    }).then(({ json }) => ({ data: json.data })),
  uploadActionVideo: ({ actionId, s3URL }: any) =>
    httpClient(`${apiUrl}/actions/${actionId}/upload_video`, {
      method: 'POST',
      body: JSON.stringify({ s3URL, actionVideoType: 'videoActionId' }),
    }).then(({ json }) => ({ data: json.data })),
  generateVideoSubtitles: ({ videoId, prompt, language }: any) =>
    httpClient(`${apiUrl}/videos/${videoId}/subtitles`, {
      method: 'POST',
      body: JSON.stringify({ prompt, language }),
    }).then(({ json }) => ({ data: json.data })),

  getDashboardData: () =>
    httpClient(`${apiUrl}/dashboard`, {
      method: 'GET',
    }).then(({ json }) => ({ data: json.data })),
  crmSearch: (q: string) =>
    httpClient(`${apiUrl}/crm/search?q=${q}`, {
      method: 'GET',
    }).then(({ json }) => ({ data: json.data })),
  crmEmailValidation: (email: string) =>
    httpClient(`${apiUrl}/crm/validate_email?email=${email}`, {
      method: 'GET',
    }).then(({ json }) => ({ data: json.data })),
  crmPersonImport: (csv: any) =>
    httpClient(`${apiUrl}/crm_persons/import`, {
      method: 'POST',
      body: JSON.stringify({ csv }),
    }).then(({ json }) => ({ data: json.data })),
  createCommentViaChat: ({ topicId, authorId, parentCommentId, message }: any) =>
    httpClient(`${apiUrl}/topics/${topicId}/chat`, {
      method: 'POST',
      body: JSON.stringify({ message, authorId, parentCommentId }),
    }).then(({ json }) => ({ data: json.data })),

  createOpenAICompletion: ({ systemMessage, prompt }: OpenAICompletion) =>
    httpClient(`${apiUrl}/openai/completion`, {
      method: 'POST',
      body: JSON.stringify({ systemMessage, prompt }),
    }).then(({ json }) => ({ data: json.data })),
  createOpenAIStructuredCompletion: ({
    systemMessage,
    prompt,
    schema,
  }: OpenAIStructuredCompletion) =>
    httpClient(`${apiUrl}/openai/structured_completion`, {
      method: 'POST',
      body: JSON.stringify({ systemMessage, prompt, schema }),
    }).then(({ json }) => ({ data: json.data })),
  startWorkflowAITask: ({ taskName, input }: any) =>
    httpClient(`${apiUrl}/workflowai/${taskName}`, {
      method: 'POST',
      body: JSON.stringify(input),
    }).then(({ json }) => ({ data: json.data })),

  /**
   * Actions endpoints
   */
  getActionAnalytics: ({ actionId, groupBy }: any) =>
    httpClient(`${apiUrl}/actions/${actionId}/analytics?groupBy=${groupBy || 'day'}`, {
      method: 'GET',
    }).then(({ json }) => ({ data: json.data })),
  generateActionInformation: ({ actionId }: any) =>
    httpClient(`${apiUrl}/actions/${actionId}/generate`, {
      method: 'PUT',
    }).then(({ json }) => ({ data: json.data })),
  getOngoingActions: () =>
    httpClient(`${apiUrl}/actions/ongoing_actions`, {
      method: 'GET',
    }).then(({ json }) => ({ data: json.data })),
};

export default dataProvider;

const convertFileToBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file.rawFile);
  });
