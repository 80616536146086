import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecordContext } from 'react-admin';
import { Card, CardContent, CardHeader, Chip, Typography } from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import {
  Bar,
  BarChart,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import moment from 'moment-timezone';
import { ActionFeedback, ActionIssue, ActionStatistics, ActionType } from 'types/action';
import dataProvider from 'src/dataProvider';

import { dateFormatter } from '@services/date';
import Callout from '@components/callout';
import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';

/**
 * Render a statistic card
 * @param number
 * @param title
 * @returns {JSX.Element}
 */
type StatisticCardProps = {
  number: string | number;
  title: string;
  description: string;
};
const StatisticCard = ({ number, title, description }: StatisticCardProps) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div
      style={styles.statisticCard}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Typography variant="h6" style={styles.statisticNumber}>
        {number}
      </Typography>
      <Typography variant="body2" style={styles.statisticTitle}>
        {title}
      </Typography>
      {isHovered && <div style={styles.floatingDescription}>{description}</div>}
    </div>
  );
};

const ActionStatistics = ({
  statistics,
}: {
  statistics: ActionStatistics | undefined;
}) => {
  if (!statistics) {
    return null;
  }

  const { totalFlowStarts, flowCompletionRate, flowAbandonmentRate, score } = statistics;

  return (
    <div>
      <div style={styles.separator} />
      {totalFlowStarts < 20 && (
        <Callout emoji="⚠️" backgroundColor="yellow">
          This action has less than 20 starts. Statistics may not be accurate.
        </Callout>
      )}
      <div style={styles.statisticsGrid}>
        <StatisticCard
          number={score.toFixed(0)}
          title="overall score"
          description="based on in-app action performance. A higher score indicates better action effectiveness. The best
        actions have a score above 80%."
        />
        <StatisticCard
          number={totalFlowStarts}
          title="total starts"
          description="the number of users who started the action"
        />
        <StatisticCard
          number={(flowCompletionRate * 100).toFixed(1) + '%'}
          title="completion rate"
          description="the percentage of users who mark the action as done after starting it"
        />
        <StatisticCard
          number={(flowAbandonmentRate * 100).toFixed(1) + '%'}
          title="abandonment rate"
          description="the percentage of users who drop the action / report an issue after starting it"
        />
      </div>
    </div>
  );
};

const FeedbackList = ({ feedbacks }: { feedbacks: ActionFeedback[] | undefined }) => {
  const getFeedbackColor = (answer: string) => {
    switch (answer) {
      case 'bug':
        return Colors.Red[600];
      case 'comments_disabled':
        return Colors.Orange[600];
      case 'not_aligned':
      case 'not_available_in_location':
        return Colors.Blue[600];
      case 'remain_anonymous':
      default:
        return Colors.Grey[600];
    }
  };

  if (!feedbacks || feedbacks.length === 0) {
    return null;
  }

  return (
    <>
      <div style={styles.separator} />
      <Typography variant="h6" style={{ marginBottom: SpacingStyle.normal }}>
        User Feedbacks
      </Typography>
      <Callout emoji="💭" backgroundColor="grey">
        <strong>{feedbacks.length}</strong> users have provided feedback on this action.
      </Callout>
      <div style={styles.feedbackList}>
        {feedbacks.map((feedback, index) => (
          <div key={index} style={styles.feedbackItem}>
            <div style={styles.feedbackHeader}>
              <Typography variant="body2" style={styles.feedbackUser}>
                {feedback.username}
              </Typography>
              <Typography variant="caption" style={styles.feedbackDate}>
                {moment(feedback.createdAt).fromNow()} (
                {dateFormatter(feedback.createdAt, { withTime: true, short: true })})
              </Typography>
            </div>
            <div style={styles.feedbackContent}>
              <Chip
                label={feedback.feedback.answer}
                size="small"
                style={{
                  backgroundColor: getFeedbackColor(feedback.feedback.answer),
                  color: 'white',
                  marginRight: SpacingStyle.small,
                }}
              />
              {feedback.feedback.comment && (
                <Typography variant="body2" style={styles.feedbackComment}>
                  {feedback.feedback.comment}
                </Typography>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

const IssuesList = ({ issues }: { issues: ActionIssue[] | undefined }) => {
  if (!issues || issues.length === 0) {
    return null;
  }

  return (
    <>
      <div style={styles.separator} />
      <Typography variant="h6" style={{ marginBottom: SpacingStyle.normal }}>
        Issues
      </Typography>
      <div style={styles.feedbackList}>
        {issues.map((issue, index) => (
          <div key={index} style={styles.feedbackItem}>
            <div style={styles.feedbackHeader}>
              <Typography variant="body2" style={styles.feedbackUser}>
                {issue.factor}
              </Typography>
              <Typography variant="caption" style={styles.feedbackDate}>
                {issue.severity}
              </Typography>
            </div>
            <div style={styles.feedbackContent}>
              <Typography variant="body2" style={styles.feedbackComment}>
                {issue.recommendation}
              </Typography>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

const AnalyticsCard = () => {
  const action: ActionType = useRecordContext();

  const [publicVisitorsTotal, setPublicVisitorsTotal] = useState<number>(0);
  const [usersActionsTotal, setUsersActionsTotal] = useState<number>(0);
  const [graphData, setGraphData] = useState<any>([]);
  const [groupBy, setGroupBy] = useState<'hour' | 'day' | 'week' | 'month'>('day');

  /**
   * Set the group by format based on groupBy
   */
  const groupByFormat = useMemo(() => {
    let format = 'YYYY-MM-MM';
    switch (groupBy) {
      case 'hour':
        format = 'YYYY-MM-DD-HH:00';
        break;
      case 'day':
        format = 'YYYY-MM-DD';
        break;
      case 'week':
        format = 'YYYY-MM-WW';
        break;
      default:
        format = 'YYYY-MM';
        break;
    }
    return format;
  }, [groupBy]);

  /**
   * Fetch action analytics
   * and set the graph data
   */
  const fetchActionAnalytics = useCallback(async () => {
    const { data } = await dataProvider.getActionAnalytics({
      actionId: action.id,
      groupBy,
    });
    const { publicVisitors, usersActions, publicVisitorsTotal, usersActionsTotal } = data;

    const graphDataTmp = [];
    const from = moment(action.publishedAt).startOf('day');
    while (from.isBefore(moment())) {
      const name = from.format(groupByFormat);
      graphDataTmp.push({
        name,
        universalLink: (publicVisitors && publicVisitors[name]) || 0,
        inApp: (usersActions && usersActions[name]) || 0,
      });
      from.add(1, groupBy);
    }

    setPublicVisitorsTotal(publicVisitorsTotal || 0);
    setUsersActionsTotal(usersActionsTotal || 0);
    setGraphData(graphDataTmp);
  }, [action, groupBy, groupByFormat]);

  useEffect(() => {
    fetchActionAnalytics();
  }, [action.id, groupBy, fetchActionAnalytics]);

  if (!action.id) {
    return <></>;
  }

  if (!action.publishedAt || moment(action.publishedAt).isAfter(moment().endOf('day'))) {
    return <></>;
  }

  return (
    <Card
      style={{
        gridColumn: 'span 2',
      }}
    >
      <CardHeader title="Analytics" avatar={<BarChartIcon />} />
      <CardContent style={{ width: '100%' }}>
        <div style={styles.groupByContainer}>
          <div style={styles.groupByLabel}>group by</div>
          <div style={styles.groupBys}>
            <div
              style={{
                ...styles.groupByButton,
                ...(groupBy === 'hour' ? styles.groupByButtonSelected : {}),
              }}
              onClick={() => setGroupBy('hour')}
            >
              hour
            </div>
            <div
              style={{
                ...styles.groupByButton,
                ...(groupBy === 'day' ? styles.groupByButtonSelected : {}),
              }}
              onClick={() => setGroupBy('day')}
            >
              day
            </div>
            <div
              style={{
                ...styles.groupByButton,
                ...(groupBy === 'week' ? styles.groupByButtonSelected : {}),
              }}
              onClick={() => setGroupBy('week')}
            >
              week
            </div>
            <div
              style={{
                ...styles.groupByButton,
                ...(groupBy === 'month' ? styles.groupByButtonSelected : {}),
              }}
              onClick={() => setGroupBy('month')}
            >
              month
            </div>
          </div>
        </div>
        <ResponsiveContainer height={400}>
          <BarChart data={graphData}>
            <XAxis
              dataKey="name"
              interval="preserveStartEnd"
              style={{
                fontSize: FontStyle.sizeSmall,
              }}
              minTickGap={1}
            />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="inApp" fill={Colors.Magenta[600]} stackId={0} />
            <Bar dataKey="universalLink" stackId={0} fill={Colors.Magenta[1300]} />
            <ReferenceLine
              y={action.usersObjective}
              stroke={Colors.Grey[300]}
              strokeDasharray="2 2"
              ifOverflow="extendDomain"
            />
          </BarChart>
        </ResponsiveContainer>
        <Callout emoji="🟰" backgroundColor="grey">
          In total, <strong>{publicVisitorsTotal + usersActionsTotal}</strong> people have
          <b>completed</b> this action:
          <br />– 📱 <strong>{usersActionsTotal}</strong> from the mobile app,
          <br />– 🔗 <strong>{publicVisitorsTotal}</strong> from the universal link.
          <p>
            The objective is <strong>{action.usersObjective}</strong> people.
          </p>
        </Callout>

        <IssuesList issues={action.issues} />
        <ActionStatistics statistics={action.statistics} />
        <FeedbackList feedbacks={action.usersFeedbacks} />
      </CardContent>
    </Card>
  );
};

const styles: any = {
  groupByContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: SpacingStyle.normal,
  },
  groupByLabel: {
    display: 'flex',
    alignItems: 'center',
    marginRight: SpacingStyle.small,
    fontSize: FontStyle.sizeVerySmall,
    color: Colors.Grey.primary,
  },
  groupBys: {
    display: 'flex',
    borderRadius: BorderStyle.Radius.small,
    overflow: 'hidden',
    border: `1px solid ${Colors.Grey[200]}`,
  },
  groupByButton: {
    padding: '2px 8px',
    cursor: 'pointer',
    fontSize: FontStyle.sizeVerySmall,
    borderRight: `.5px solid ${Colors.Grey[200]}`,
  },
  groupByButtonSelected: {
    backgroundColor: Colors.Grey[200],
  },
  statisticsGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: SpacingStyle.normal,
  },
  statisticCard: {
    position: 'relative',
    padding: SpacingStyle.small,
    backgroundColor: Colors.Background.grey,
    borderRadius: BorderStyle.Radius.small,
    textAlign: 'center',
  },
  statisticNumber: {
    color: Colors.Magenta[600],
    fontWeight: 'bold',
  },
  statisticTitle: {
    color: Colors.Grey.primary,
  },
  scoreExplanation: {
    marginTop: SpacingStyle.normal,
    color: Colors.Grey.primary,
    fontSize: FontStyle.sizeSmall,
  },
  floatingDescription: {
    position: 'absolute',
    top: '110%',
    left: '-5%',
    width: '110%',
    padding: SpacingStyle.small,
    backgroundColor: Colors.Background.grey,
    borderRadius: BorderStyle.Radius.small,
    zIndex: 1,
    boxShadow: '0 0 12px rgba(0, 0, 0, 0.1)',
    fontSize: FontStyle.sizeVerySmall,
    lineHeight: 1.2,
    color: Colors.Grey.primary,
  },
  feedbackList: {
    display: 'flex',
    flexDirection: 'column',
    gap: SpacingStyle.normal,
    marginBottom: SpacingStyle.big,
    maxHeight: 300,
    overflowY: 'auto',
  },
  feedbackItem: {
    padding: SpacingStyle.normal,
    backgroundColor: Colors.Background.grey,
    borderRadius: BorderStyle.Radius.small,
  },
  feedbackHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: SpacingStyle.small,
  },
  feedbackUser: {
    fontWeight: 'bold',
    color: Colors.Grey[800],
  },
  feedbackDate: {
    color: Colors.Grey[600],
  },
  feedbackContent: {
    display: 'flex',
    alignItems: 'center',
  },
  feedbackComment: {
    color: Colors.Grey[800],
  },
  separator: {
    height: 1,
    width: '100%',
    backgroundColor: Colors.Grey[100],
    marginTop: SpacingStyle.big,
    marginBottom: SpacingStyle.big,
  },
};

export default AnalyticsCard;
