import React, { useCallback, useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import { ActionType } from 'types/action';
import moment from 'moment-timezone';
import FeedbackIcon from '@mui/icons-material/Feedback';

import { dateFormatter } from '@services/date';
import ellipsis from '@services/ellipsis';
import LoadingAnimation from '@components/svgs/loading_animation';
import CampaignBlock from '@models/campaigns/components/campaign_block';
import CauseChip from '@models/causes/components/chip';
import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';

const OngoingActionsList = () => {
  const dataProvider = useDataProvider();
  const [actions, setActions] = useState<ActionType[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchOngoingActions = useCallback(async () => {
    try {
      const { data } = await dataProvider.getOngoingActions();
      setActions(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [dataProvider]);

  useEffect(() => {
    fetchOngoingActions();
  }, [fetchOngoingActions]);

  return (
    <div style={styles.container}>
      {isLoading && <LoadingAnimation />}
      <table width={'100%'} style={{ textAlign: 'center' }} border={0} cellSpacing={0}>
        <thead>
          <tr
            style={{
              backgroundColor: Colors.Black.transparent.max,
              fontSize: FontStyle.sizeVerySmall,
              height: 40,
            }}
          >
            <th>Cause</th>
            <th>Campaign</th>
            <th>Action</th>
            <th>Published At</th>
            <th>Expiring in</th>
            <th>Tags</th>
            <th>Score</th>
            <th>CompletionRate</th>
            <th>Feedbacks</th>
          </tr>
        </thead>
        <tbody>
          {actions.map((action: ActionType, index: number) => {
            const publishedAt = moment(action.publishedAt).isAfter(moment())
              ? moment(action.publishedAt).fromNow()
              : dateFormatter(action.publishedAt, { short: true });
            const expiresOn = moment(action.publishedAt).add(action.expiresIn, 'days');
            const completionRate = Math.round(
              (action.statistics?.flowCompletionRate || 0) * 100
            );
            return (
              <tr
                key={action.id}
                style={{
                  backgroundColor:
                    index % 2 === 0 ? Colors.White.primary : Colors.Background.light,
                }}
              >
                <td
                  style={{
                    ...styles.td,
                    ...styles.tdAlignedLeft,
                  }}
                >
                  <CauseChip cause={action.cause} />
                </td>
                <td
                  style={{
                    ...styles.td,
                    ...styles.tdAlignedLeft,
                  }}
                >
                  <CampaignBlock campaign={action.campaign} size="small" clickable />
                </td>
                <td
                  style={{
                    ...styles.td,
                    ...styles.tdAlignedLeft,
                  }}
                >
                  <a href={`#/actions/${action.id}/show`} style={styles.actionName}>
                    {ellipsis(action.name, 40)}
                  </a>
                </td>
                <td style={{ ...styles.td, ...styles.tdSmall }}>{publishedAt}</td>
                <td
                  style={{ ...styles.td, ...styles.tdSmall }}
                  title={dateFormatter(expiresOn.toISOString(), { short: true })}
                >
                  {expiresOn.fromNow()}
                </td>
                <td style={styles.td}>
                  {action.priority > 1 && (
                    <div style={{ ...styles.tag, ...styles.priority }}>
                      all hands on deck
                    </div>
                  )}
                  {action.difficulty === 1 && (
                    <div style={{ ...styles.tag, ...styles.difficulty }}>easy</div>
                  )}
                </td>
                <td
                  style={{
                    ...styles.td,
                    ...styles.tdSmall,
                  }}
                >
                  {Math.round(action.statistics?.score || 0)}
                </td>
                <td
                  style={{
                    ...styles.td,
                    ...styles.tdSmall,
                    color: completionRate < 75 ? Colors.Red.primary : Colors.Grey.primary,
                  }}
                >
                  {completionRate}%
                </td>
                <td style={styles.td}>
                  {action.usersFeedbacksCount && action.usersFeedbacksCount > 0 && (
                    <div style={{ ...styles.tag, ...styles.feedback }}>
                      <FeedbackIcon style={{ fontSize: 'inherit' }} />
                      {action.usersFeedbacksCount}
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const styles: any = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  td: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: FontStyle.sizeSmall,
  },
  tdAlignedLeft: {
    textAlign: 'left',
  },
  tdSmall: {
    fontSize: FontStyle.sizeVerySmall,
  },
  tag: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: FontStyle.sizeVerySmall,
    fontWeight: 700,
    color: Colors.White.primary,
    padding: `0 ${SpacingStyle[4]}px`,
    borderRadius: BorderStyle.Radius.small,
    gap: SpacingStyle[2],
  },
  actionName: {
    color: Colors.OffBlack.primary,
    fontWeight: 600,
    textDecoration: 'none',
  },
  difficulty: {
    backgroundColor: Colors.Blue.primary,
  },
  priority: {
    backgroundColor: Colors.Orange.primary,
  },
  feedback: {
    backgroundColor: Colors.Red.primary,
  },
};

export default OngoingActionsList;
