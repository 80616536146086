import React from 'react';
import { AutocompleteInput, required as RARequired } from 'react-admin';

import { US_STATES } from '@services/regions';

const regionsChoices = US_STATES.map(state => ({
  id: state.name,
  name: `${state.name} (${state.abbreviation})`,
}));

type RegionInputProps = {
  source: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  helperText?: string;
  emptyText?: string;
  fullWidth?: boolean;
  multiple?: boolean;
  onChange?: (event: any) => void;
};
const RegionInput = ({
  source,
  label,
  required,
  disabled,
  helperText,
  emptyText,
  fullWidth,
  multiple = false,
  onChange,
}: RegionInputProps) => (
  <AutocompleteInput
    choices={regionsChoices}
    source={source}
    label={label}
    validate={required ? [RARequired()] : []}
    disabled={disabled}
    helperText={helperText}
    emptyText={emptyText}
    fullWidth={fullWidth}
    style={{ minWidth: 300 }}
    multiple={multiple}
    onChange={onChange}
  />
);

export default RegionInput;
