import * as React from 'react';
import {
  DateTimeInput,
  Edit,
  Labeled,
  ReferenceField,
  SimpleForm,
  TextInput,
} from 'react-admin';

import ResourceTitleActionBar from '@components/resource_title_action_bar';

const redirectAfterEdit = (basePath: any, id: any, data: any) =>
  `causes_posts_comments/${data.id}/show`;

const EditActions = <ResourceTitleActionBar mode="edit" />;

export const CausesPostsCommentEdit = () => (
  <Edit redirect={redirectAfterEdit} mutationMode="pessimistic" actions={EditActions}>
    <SimpleForm>
      <Labeled label="Author">
        <ReferenceField source="authorId" reference="users" link="show" />
      </Labeled>
      <TextInput source="message" fullWidth multiline />
      <DateTimeInput source="createdAt" />
    </SimpleForm>
  </Edit>
);
