import React, { useCallback, useEffect, useState } from 'react';
import { Button, useDataProvider } from 'react-admin';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { ActionType } from 'types/action';
import { Card, CardContent, CardHeader } from '@mui/material';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import LoadingAnimation from '@components/svgs/loading_animation';
import ActionCard from '@components/calendar/components/action_card';
import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';

const ProblematicActions = () => {
  const dataProvider = useDataProvider();
  const { isSuperAdmin, isCampaignAdmin } = useRoleBasedPermissions();
  const [actions, setActions] = useState<ActionType[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const perPage = 25;

  const fetchActions = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data, total } = await dataProvider.getList('problematic_actions', {
        pagination: { page, perPage },
        sort: { field: 'publishedAt', order: 'DESC' },
        filter: {},
      });
      setActions(data);
      if (total && total > 0) {
        setTotalPages(Math.ceil(total / perPage));
      }
    } finally {
      setIsLoading(false);
    }
  }, [dataProvider, page]);

  useEffect(() => {
    fetchActions();
  }, [fetchActions, page]);

  const renderActionCard = (action: ActionType) => (
    <ActionCard
      key={action.id}
      action={action}
      withCampaign
      withCause
      withPublishedAtDate
      withLocation
      showAlert={false}
    />
  );

  if (!isSuperAdmin && !isCampaignAdmin) {
    return null;
  }

  if (actions.length === 0) {
    return null;
  }

  return (
    <Card style={styles.container}>
      <CardHeader
        style={styles.header}
        title="Actions that need attention"
        avatar="🚨"
        action={
          <div style={styles.headerRight}>
            {isLoading && <LoadingAnimation width={18} height={18} />}
            {!isLoading && totalPages > 1 && (
              <div style={styles.pagination}>
                <Button
                  onClick={() => setPage(p => Math.max(1, p - 1))}
                  disabled={page === 1}
                  label=""
                  children={<BiChevronLeft style={styles.paginationIcon} />}
                />
                <span style={styles.paginationText}>
                  Page {page} of {totalPages}
                </span>
                <Button
                  onClick={() => setPage(p => Math.min(totalPages, p + 1))}
                  disabled={page === totalPages}
                  label=""
                  children={<BiChevronRight style={styles.paginationIcon} />}
                />
              </div>
            )}
          </div>
        }
      />
      <CardContent>
        <div style={styles.grid}>{actions.map(renderActionCard)}</div>
      </CardContent>
    </Card>
  );
};

const styles: any = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: SpacingStyle.normal,
    minHeight: 300,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: SpacingStyle.small,
  },
  headerTitle: {
    fontSize: FontStyle.sizeNormal,
    fontWeight: 700,
  },
  headerRight: {
    display: 'flex',
    alignItems: 'center',
    gap: SpacingStyle.small,
  },
  pagination: {
    display: 'flex',
    alignItems: 'center',
    gap: SpacingStyle.small,
  },
  paginationText: {
    fontSize: FontStyle.sizeVerySmall,
    color: Colors.Grey[600],
  },
  paginationIcon: {
    width: 20,
    height: 20,
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gap: SpacingStyle.normal,
    backgroundColor: Colors.White.primary,
  },
  actionCard: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 140,
    padding: SpacingStyle.normal,
    backgroundColor: Colors.White.primary,
    borderRadius: BorderStyle.Radius.tiny,
    border: `0.5px solid ${Colors.Grey[50]}`,
  },
  actionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: SpacingStyle.small,
  },
  actionTitle: {
    fontSize: FontStyle.sizeSmall,
    fontWeight: 600,
    color: Colors.Black.primary,
  },
  actionDate: {
    fontSize: FontStyle.sizeVerySmall,
    color: Colors.Grey[500],
  },
  actionContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: SpacingStyle.small,
    flex: 1,
  },
  actionDescription: {
    fontSize: FontStyle.sizeVerySmall,
    color: Colors.Grey[700],
    lineHeight: 1.4,
    flex: 1,
  },
  actionStats: {
    display: 'flex',
    gap: SpacingStyle.small,
    marginTop: 'auto',
  },
  statItem: {
    fontSize: FontStyle.sizeVerySmall,
    color: Colors.Grey[600],
    padding: `${SpacingStyle[2]}px ${SpacingStyle[8]}px`,
    backgroundColor: Colors.Grey[50],
    borderRadius: BorderStyle.Radius.big,
  },
};

export default ProblematicActions;
