import React, { useEffect, useState } from 'react';
import {
  Datagrid,
  DateField,
  Filter,
  Form,
  FunctionField,
  List,
  ReferenceField,
  SearchInput,
  TextField,
} from 'react-admin';
import ReactMarkdown from 'react-markdown';
import { useLocation } from 'react-router-dom';
import { CommentType } from 'types/comment';

import ellipsis from '@services/ellipsis';
import { PER_PAGE, PER_PAGE_OPTIONS } from '@components/list';
import ResourceTitleActionBar from '@components/resource_title_action_bar';
import CustomReferenceInput from '@components/inputs/custom_reference_input';
import { SpacingStyle } from '@styles/variables';

const ListActions = () => {
  return (
    <ResourceTitleActionBar
      mode="list"
      actions={
        <div>
          <Filter>
            <SearchInput source="message" alwaysOn />
          </Filter>
        </div>
      }
    />
  );
};

export const CausesPostsCommentList = () => {
  return (
    <List
      actions={<ListActions />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      perPage={PER_PAGE}
      pagination={<PER_PAGE_OPTIONS />}
      exporter={false}
      empty={false}
      emptyWhileLoading
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <ReferenceField source="authorId" reference="users" link="show" />

        <DateField
          source="createdAt"
          label={`Posted at (${Intl.DateTimeFormat().resolvedOptions().timeZone})`}
          showTime
        />

        <TextField source="message" label="Message" />
      </Datagrid>
    </List>
  );
};
