import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DateTimeInput,
  Form,
  ImageField,
  ImageInput,
  required,
  useDataProvider,
  useRedirect,
} from 'react-admin';
import * as z from 'zod';
import { useSearchParams } from 'react-router-dom';

import ResourceTitleActionBar from '@components/resource_title_action_bar';
import CardStepper from '@components/card_stepper';
import PepperBuilderChat from '@components/pepper_builder_chat';
import CustomReferenceInput from '@components/inputs/custom_reference_input';
import Callout from '@components/callout';
import { SpacingStyle } from '@styles/variables';

import CausePostPreview from './components/preview';

const CreateActions = <ResourceTitleActionBar mode="create" title="Causes Posts" />;

export const CausePostCreate = () => {
  const [searchParams] = useSearchParams();
  const [causeId, setCauseId] = useState<string | null>();
  const [publishedAt, setPublishedAt] = useState<null>();
  const [imageText, setImageText] = useState('image text');
  const [titleEN, setTitleEN] = useState('English title');
  const [titleFR, setTitleFR] = useState('French title');
  const [contentEN, setContentEN] = useState(
    'English content, something long and descriptive'
  );
  const [contentFR, setContentFR] = useState(
    'French content, something long and descriptive'
  );
  const [picture, setPicture] = useState<any>();
  const resourceEN = useMemo(() => {
    let medias;
    if (searchParams.get('imageUrl')) {
      medias = [{ imageUrl: searchParams.get('imageUrl') }];
    }
    return { titleEN, contentEN, medias };
  }, [titleEN, contentEN, searchParams]);
  const resourceFR = useMemo(() => {
    let medias;
    if (searchParams.get('imageUrl')) {
      medias = [{ imageUrl: searchParams.get('imageUrl') }];
    }
    return { titleFR, contentFR, medias };
  }, [titleFR, contentFR, searchParams]);

  const redirect = useRedirect();
  const dataProvider = useDataProvider();

  const canPublish = useMemo(() => causeId && picture, [causeId, picture]);

  useEffect(() => {
    console.log(searchParams.get('imageUrl'));
  }, [searchParams]);

  const fetchCause = useCallback(
    async (descriptionEN: string) => {
      if (descriptionEN) {
        const { data } = await dataProvider.getList('causes', {
          pagination: { page: 1, perPage: 1 },
          filter: { descriptionEN },
          sort: { field: 'descriptionEN', order: 'ASC' },
        });
        if (data?.length && data[0].id) {
          setCauseId(data[0].id);
        }
      }
    },
    [dataProvider]
  );

  useEffect(() => {
    if (!searchParams) {
      return;
    }
    if (searchParams.get('cause')) {
      fetchCause(searchParams.get('cause') as string);
    }
    if (searchParams.get('titleEN')) {
      setTitleEN(searchParams.get('titleEN') as string);
    }
    if (searchParams.get('titleFR')) {
      setTitleFR(searchParams.get('titleFR') as string);
    }
    if (searchParams.get('contentEN')) {
      setContentEN(searchParams.get('contentEN') as string);
    }
    if (searchParams.get('contentFR')) {
      setContentFR(searchParams.get('contentFR') as string);
    }
    if (searchParams.get('imageText')) {
      setImageText(searchParams.get('imageText') as string);
    }
  }, [searchParams, fetchCause]);

  /**
   * Zod schema for the cause post
   */
  const causePostSchema = z.object({
    imageText: z
      .string()
      .describe(
        "A text that will be displayed on the image. It's a catchy short phrase that captures the main topic of the article or post. This text should be short and concise, maximum 40 charcaters, and should be written in lowercase letters."
      ),
    titleEN: z
      .string()
      .describe(
        'A concise title in English that captures the main topic of the article or post. The title should be written in lowercase letters, with only the first letter capitalized.'
      ),
    titleFR: z
      .string()
      .describe(
        'A concise title in French that captures the main topic of the article or post. The title should be written in lowercase letters, with only the first letter capitalized.'
      ),
    contentEN: z
      .string()
      .describe(
        'Write a brief summary of the article or post in English, using a few sentences to cover the key points. Include a new line (line break) after each idea, to enhance readability.'
      ),
    contentFR: z
      .string()
      .describe(
        'Write a brief summary of the article or post in French, using a few sentences to cover the key points. Include a new line (line break) after each idea, to enhance readability.'
      ),
  });

  /**
   * Handler for when a message is submitted in the chat
   * @param output
   * @returns {Promise<void>}
   */
  const onChatMessageSubmittedHandler = useCallback(
    async (output: z.infer<typeof causePostSchema>) => {
      try {
        const { imageText, titleEN, titleFR, contentEN, contentFR } = output;
        setImageText(imageText);
        setTitleEN(titleEN);
        setTitleFR(titleFR);
        setContentEN(contentEN);
        setContentFR(contentFR);
      } catch (error) {
        console.error('CausePostCreate.onSubmit', error);
      }
    },
    []
  );

  const onFormSubmit = async () => {
    try {
      const { data } = await dataProvider.create('causes_posts', {
        data: {
          causeId,
          titleEN,
          titleFR,
          contentEN,
          contentFR,
          picture,
          imageUrl: searchParams?.get('imageUrl'),
          publishedAt,
        },
      });
      redirect('show', 'causes_posts', data.id);
    } catch (error) {
      console.error('CausePostCreate.onFormSubmit', error);
    }
  };

  const steps = [
    // Step1 - Cause selection
    {
      title: "What's the cause this post is about?",
      children: (
        <Form>
          <CustomReferenceInput
            source="id"
            reference="causes"
            queryKey="descriptionEN"
            validate={[required()]}
            label="Cause"
            sort={{ field: 'descriptionEN', order: 'ASC' }}
            defaultValue={causeId}
            onChange={setCauseId}
          />
        </Form>
      ),
    },
    // Step2 - Create Post
    {
      title: "What's the cause this post is about?",
      children: (
        <PepperBuilderChat
          welcomeText="Write as much context as you can on the post you want to create"
          onMessageSubmitted={onChatMessageSubmittedHandler}
          systemMessage="You are specialized in writing shorts blog posts from a chat with someone. You will generate a title and some content in English and French, based on the content of your conversation."
          zodSchema={causePostSchema}
          initialContext={{
            imageText,
            titleEN,
            titleFR,
            contentEN,
            contentFR,
          }}
          previewContainer={
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                gap: SpacingStyle.big,
              }}
            >
              <CausePostPreview
                record={resourceEN as any}
                locale="en"
                imageText={imageText}
              />
              <CausePostPreview
                record={resourceFR as any}
                locale="fr"
                imageText={imageText}
              />
            </div>
          }
        />
      ),
    },
    // Step 3 - Add image
    {
      title: 'Add image',
      children: (
        <Form>
          {searchParams.has('imageUrl') && (
            <img
              src={searchParams.get('imageUrl') as string}
              style={{
                maxWidth: '100%',
                height: 'auto',
                aspectRatio: '1',
                objectFit: 'cover',
              }}
            />
          )}
          <ImageInput
            source="picture"
            accept="image/*"
            maxSize={5000000}
            validate={searchParams.has('imageUrl') ? undefined : [required()]}
            parse={v => {
              setPicture({ rawFile: v });
              return v;
            }}
          >
            <ImageField source="src" title="title" />
          </ImageInput>
          <Callout backgroundColor="grey">
            A squared image is recommended for the best display. The image should be at
            least 500x500.
          </Callout>
        </Form>
      ),
    },
    // Step 4 - Choose a publication date (optional)
    {
      title: 'Choose a publication date (optional)',
      children: (
        <Form>
          <DateTimeInput
            source="publishedAt"
            onChange={event => setPublishedAt(event.target.value)}
            disabled={!canPublish}
            fullWidth
          />
          {!canPublish && (
            <Callout backgroundColor="red" emoji="✋">
              Select a cause and an image to be able to publish.
              <br />
              You can still save this post as a <strong>draft</strong>.
            </Callout>
          )}
        </Form>
      ),
    },
  ];

  return (
    <>
      {CreateActions}
      <CardStepper steps={steps} onCompleted={onFormSubmit} fullHeight />
    </>
  );
};
