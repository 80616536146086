import React, { useMemo } from 'react';
import { useRedirect } from 'react-admin';
import ReactMarkdown from 'react-markdown';
import { CommentType } from 'types/comment';
import { CommentMediaTypes } from 'types/comment_media.d';
import { UserCommentReactionType } from 'types/user_comment_reaction';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';

import { UserAvatar } from '@models/users/components/user_avatar';
import { dateFormatter } from '@services/date';
import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';
import Edit from '@components/svgs/edit';

/**
 * CommentMedia
 * @param comment
 * @returns {JSX.Element}
 */
const CommentMedia = ({ comment }: { comment: CommentType }) => {
  if (!comment) {
    return;
  }

  return (
    <div style={styles.commentMedias}>
      {comment.medias?.map(
        media =>
          media && (
            <a href={media.url} target="_blank" key={media.id}>
              {media.type === CommentMediaTypes.Video && (
                <video
                  src={media.url}
                  style={styles.commentMedia}
                  controls={false}
                  autoPlay={false}
                />
              )}
              {media.type === CommentMediaTypes.Audio && (
                <audio
                  src={media.url}
                  style={styles.commentMedia}
                  controls={false}
                  autoPlay={false}
                />
              )}
              {(media.type === CommentMediaTypes.Image ||
                media.type === CommentMediaTypes.Gif) && (
                <img src={media.url} style={styles.commentMedia} />
              )}
            </a>
          )
      )}
    </div>
  );
};

/**
 * CommentBlock
 */
const CommentBlock = ({
  comment,
  commentResourceType,
  isParentComment,
  isInThread,
  spyVersion,
  setSearchParams,
}: {
  comment: CommentType;
  commentResourceType: 'topic_comments' | 'causes_posts_comments';
  isParentComment?: boolean;
  isInThread?: boolean;
  spyVersion: boolean;
  setSearchParams?: any;
}) => {
  const redirect = useRedirect();

  const groupedReactions = useMemo(() => {
    const reactions = comment?.reactions || [];
    return reactions.reduce(
      (acc, reaction) => {
        if (!acc[reaction.label]) {
          acc[reaction.label] = [];
        }
        acc[reaction.label].push(reaction);
        return acc;
      },
      {} as Record<string, UserCommentReactionType[]>
    );
  }, [comment]);

  return (
    <div
      style={{
        ...styles.commentContainer,
        ...(isParentComment ? styles.parentCommentContainer : null),
      }}
    >
      {/* Header */}
      <div style={styles.commentHeader}>
        <a style={styles.commentHeaderLeft} href={`#/users/${comment.authorId}/show`}>
          {comment.author && (
            <UserAvatar style={styles.commentAuthorImage} record={comment.author} />
          )}
          <div style={styles.commentHeaderAuthor}>{comment.author?.username}</div>
        </a>
        <div style={styles.commentHeaderDate}>
          {dateFormatter(comment.createdAt, {
            withTime: true,
            short: true,
          })}
        </div>
      </div>

      {/* Comment */}
      <ReactMarkdown>
        {(spyVersion ? comment.messageForSpies : comment.messageSanitized) ||
          comment.silentMessageEN ||
          ''}
      </ReactMarkdown>

      {/* Media */}
      <CommentMedia comment={comment} />

      {/* Footer */}
      {!!comment.message && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: SpacingStyle.small,
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: SpacingStyle[4],
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            {Object.entries(groupedReactions)?.map(userReaction => (
              <div key={userReaction[0]} style={styles.commentReaction}>
                {userReaction[0]} {userReaction[1].length}
              </div>
            ))}
          </div>
          <div
            style={{
              display: 'flex',
              gap: SpacingStyle[4],
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <div
              style={styles.commentOpenThread}
              onClick={() => redirect('edit', commentResourceType, comment.id)}
            >
              <Edit width={12} height={12} stroke={Colors.Grey.primary} />
              edit
            </div>
            {!!comment?.highlight?.id && (
              <div
                onClick={() =>
                  redirect('show', 'topic_highlights', comment!.highlight!.id)
                }
                style={styles.commentOpenThread}
              >
                <ElectricBoltIcon style={{ fontSize: 12 }} />
                open highlight
              </div>
            )}
            {!isInThread && !comment.parentCommentId && (
              <div
                style={styles.commentOpenThread}
                onClick={() => setSearchParams?.({ commentId: comment.id })}
              >
                open thread
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
const styles: any = {
  parentCommentContainer: {
    margin: SpacingStyle.small,
    backgroundColor: Colors.Magenta[50],
    border: `1px solid ${Colors.Magenta.primary}`,
    borderRadius: BorderStyle.Radius.normal,
  },
  commentContainer: {
    padding: `${SpacingStyle.normal}px ${SpacingStyle.normal}px`,
    backgroundColor: Colors.White.primary,
    borderBottom: `1px solid ${Colors.Grey[50]}`,
  },
  commentHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  commentHeaderLeft: {
    display: 'flex',
    alignItems: 'center',
    gap: SpacingStyle.small,
    textDecoration: 'none !important',
  },
  commentHeaderAuthor: {
    color: Colors.OffBlack.primary,
    fontSize: FontStyle.sizeSmall,
    fontWeight: 700,
  },
  commentHeaderDate: {
    fontSize: FontStyle.sizeVerySmall,
    color: Colors.Grey[600],
  },
  commentOpenThread: {
    display: 'flex',
    alignItems: 'center',
    gap: SpacingStyle[4],
    padding: '2px 8px',
    textAlign: 'right',
    fontSize: FontStyle.sizeVeryVerySmall,
    fontWeight: 400,
    color: Colors.Grey[800],
    cursor: 'pointer',
    border: `1px solid ${Colors.Grey[300]}`,
    borderRadius: BorderStyle.Radius.normal,
  },
  commentMedias: {
    display: 'flex',
    flexDirection: 'row',
    gap: SpacingStyle.small,
    marginBottom: SpacingStyle.small,
  },
  commentMedia: {
    display: 'block',
    width: '100%',
    height: '100%',
    maxWidth: 200,
    maxHeight: 120,
    objectFit: 'cover',
    borderRadius: BorderStyle.Radius.small,
    border: `0.5px solid ${Colors.Grey.primary}`,
    backgroundColor: Colors.Grey[200],
  },
  commentReaction: {
    fontSize: FontStyle.sizeVerySmall,
    color: Colors.Grey[600],
    backgroundColor: Colors.OffWhite.primary,
    border: `1px solid ${Colors.Grey[100]}`,
    borderRadius: BorderStyle.Radius.normal,
    padding: '0 5px',
    userSelect: 'none',
  },
};

export default CommentBlock;
