import React from 'react';
import { SelectInput, required } from 'react-admin';
import { CommentEntityType } from 'types/comment.d';
import { DonationType } from 'types/donation';
import { Deal } from 'types/deal';

import { dateFormatter } from '@services/date';
import ellipsis from '@services/ellipsis';
import CustomReferenceInput from '@components/inputs/custom_reference_input';

export const appPages = [
  {
    id: 'TopicComment',
    name: '💬 Topic Comment',
    children: (
      <CustomReferenceInput
        source="appPageObjectId"
        reference="topic_comments"
        queryKey="message"
        label="Choose a comment"
        filters={{
          parentCommentId: null,
          entityType: CommentEntityType.TOPIC,
          silentMessageEN: null,
          silentMessageFR: null,
        }}
        optionText={option =>
          `[${dateFormatter(option.createdAt, {
            short: true,
            withTime: true,
          })}]\t${ellipsis(option.message, 120)}`
        }
        sort={{ field: 'createdAt', order: 'DESC' }}
        validate={[required()]}
      />
    ),
  },
  {
    id: 'Shop',
    name: '🎁 Shop (main page)',
  },
  {
    id: 'ShopDealProfile',
    name: '🎁 Deal Profile',
    children: (
      <CustomReferenceInput
        source="appPageObjectId"
        reference="deals"
        queryKey="nameEN"
        label="Choose a deal"
        optionText={(deal: Deal) =>
          deal.launchedAt
            ? `${deal.brandNameEN}: ${deal.nameEN}`
            : `[DRAFT] ${deal.nameEN} (wont work!)`
        }
        sort={{ field: 'createdAt', order: 'ASC' }}
        validate={[required()]}
      />
    ),
  },
  {
    id: 'ShopDonationProfile',
    name: '🎁 Donation Profile',
    children: (
      <CustomReferenceInput
        source="appPageObjectId"
        reference="donations"
        queryKey="nameEN"
        label="Choose a donation"
        optionText={(donation: DonationType) =>
          donation.launchedAt
            ? `${donation.nameEN}`
            : `[DRAFT] ${donation.nameEN} (wont work!)`
        }
        sort={{ field: 'createdAt', order: 'ASC' }}
        validate={[required()]}
      />
    ),
  },
];

export const AppPageSelectInput = () => (
  <SelectInput
    source="page"
    fullWidth
    validate={[required()]}
    choices={appPages.map(page => ({
      id: page.id,
      name: page.name,
    }))}
  />
);
