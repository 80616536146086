import UserChip from '@models/users/components/user_chip';
import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';
import React, { useCallback, useMemo, useState } from 'react';
import { UserType } from 'types/user';

type AuthorsModalProps = {
  authors: UserType[];
  open: boolean;
  onClose: () => void;
  onSelect: (author: UserType) => void;
};
const AuthorsModal = ({ authors, open, onClose, onSelect }: AuthorsModalProps) => {
  const [searchQuery, setSearchQuery] = useState('');

  /**
   * Sort authors by username
   * @param authorA
   * @param authorB
   * @returns {number}
   */
  const sortAuthors = (authorA: UserType, authorB: UserType) => {
    if (!authorA.username || !authorB.username) {
      return 0;
    }
    if (authorA.username.toLowerCase() < authorB.username.toLowerCase()) {
      return -1;
    }
    if (authorA.username.toLowerCase() > authorB.username.toLowerCase()) {
      return 1;
    }
    return 0;
  };

  /**
   * Filter authors based on search query
   * @returns {UserType[]}
   */
  const filteredList = useMemo(() => {
    if (!searchQuery) {
      return authors.sort(sortAuthors).slice(0, 10);
    }
    return authors
      .filter(author => author.username.toLowerCase().includes(searchQuery.toLowerCase()))
      .slice(0, 20);
  }, [authors, searchQuery]);

  /**
   * Handle key down, when enter is pressed, select the first author
   * @param event
   */
  const onKeyDown = useCallback(
    (event: any) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onSelect(filteredList[0]);
      }
    },
    [filteredList, onSelect]
  );

  if (!open) {
    return null;
  }

  return (
    <div>
      <div style={styles.background} onClick={onClose} />
      <div style={styles.wrapper}>
        <div style={styles.container}>
          <div style={styles.header}>Select an author </div>
          <div style={styles.list}>
            {filteredList.sort(sortAuthors).map((author: UserType) => (
              <UserChip
                key={author.id}
                user={author}
                onClick={() => onSelect(author)}
                style={styles.listItem}
              />
            ))}
          </div>
          <input
            type="text"
            ref={input => input && input.focus()}
            onChange={event => setSearchQuery(event.target.value)}
            onKeyDown={onKeyDown}
            style={styles.input}
            value={searchQuery}
            placeholder="Search author by username..."
          />
        </div>
      </div>
    </div>
  );
};

const styles: any = {
  background: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: Colors.White.transparent.max,
    backdropFilter: 'blur(4px)',
    zIndex: 10,
  },
  wrapper: {
    position: 'fixed',
    bottom: 300,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    zIndex: 15,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: SpacingStyle.small,
    maxHeight: '55vh',
    overflowY: 'auto',
    backgroundColor: Colors.White.primary,
    borderRadius: BorderStyle.Radius.small,
    boxShadow: '0 2px 20px rgba(0, 0, 0, 0.1)',
  },
  header: {
    fontSize: FontStyle.sizeSmall,
    fontWeight: 600,
    marginBottom: SpacingStyle.small,
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: SpacingStyle.small,
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 240,
    padding: SpacingStyle.small,
    gap: SpacingStyle.small,
    borderRadius: BorderStyle.Radius.normal,
    backgroundColor: Colors.Grey[50],
    cursor: 'pointer',
  },
  input: {
    marginTop: SpacingStyle.normal,
    padding: SpacingStyle.small,
    borderRadius: BorderStyle.Radius.small,
    border: `1px solid ${Colors.Black.transparent.max}`,
    backgroundColor: Colors.OffWhite.primary,
    fontSize: FontStyle.sizeSmall,
  },
};

export default AuthorsModal;
