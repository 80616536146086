import React, { memo, useEffect, useState } from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';

import { ActionIcon } from '@components/icons';
import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';

import ActionsCalendar from './actions_calendar';
import OngoingActionsList from './ongoing_actions_list';

const CausesCalendars = () => {
  const [view, setView] = useState<'calendar' | 'list'>(
    () => (localStorage.getItem('view') as 'calendar' | 'list') || 'calendar'
  );

  useEffect(() => {
    localStorage.setItem('view', view);
  }, [view]);

  const Switch = memo(() => (
    <div style={SwitchStyle.container}>
      <div
        style={{
          ...SwitchStyle.item,
          ...(view === 'calendar' ? SwitchStyle.active : {}),
        }}
        onClick={() => setView('calendar')}
      >
        Calendar
      </div>
      <div
        style={{
          ...SwitchStyle.item,
          ...(view === 'list' ? SwitchStyle.active : {}),
        }}
        onClick={() => setView('list')}
      >
        List
      </div>
    </div>
  ));

  return (
    <Card>
      <CardHeader
        avatar={<ActionIcon />}
        title={view === 'list' ? 'Ongoing Actions' : 'Actions'}
        action={<Switch />}
      />
      <CardContent>
        {view === 'list' && <OngoingActionsList />}
        {view === 'calendar' && <ActionsCalendar />}
      </CardContent>
    </Card>
  );
};

const SwitchStyle: any = {
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '1rem',
    borderRadius: BorderStyle.Radius.huge,
    overflow: 'hidden',
    border: `1px solid ${Colors.OffBlack.primary}`,
  },
  item: {
    cursor: 'pointer',
    padding: `${SpacingStyle[4]}px ${SpacingStyle.small}px`,
    borderRadius: BorderStyle.Radius.huge,
    minWidth: 50,
    fontSize: FontStyle.sizeVeryVerySmall,
    fontWeight: '800',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  active: {
    backgroundColor: Colors.OffBlack.primary,
    color: Colors.White.primary,
  },
};

export default CausesCalendars;
