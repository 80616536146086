import React, { useEffect, useMemo, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import dataProvider from 'src/dataProvider';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import { Colors } from '@styles/variables';

const NorthStarMetricCard = () => {
  const [today, setToday] = useState<number>();
  const [yesterday, setYesterday] = useState<number>();
  const [currentWeek, setCurrentWeek] = useState<number>();
  const [pastWeek, setPastWeek] = useState<number>();
  const [currentMonth, setCurrentMonth] = useState<number>();
  const [pastMonth, setPastMonth] = useState<number>();
  const { isSuperAdmin, isCampaignAdmin, isProduct } = useRoleBasedPermissions();
  const canView = useMemo(
    () => isSuperAdmin || isCampaignAdmin || isProduct,
    [isSuperAdmin, isCampaignAdmin, isProduct]
  );

  useEffect(() => {
    if (canView) {
      fetchDashboardData();
    }
  }, [canView]);

  const fetchDashboardData = async () => {
    const { data } = await dataProvider.getDashboardData();
    setToday(data.northStarMetric?.today);
    setYesterday(data.northStarMetric?.yesterday);
    setCurrentWeek(data.northStarMetric?.currentWeek);
    setPastWeek(data.northStarMetric?.pastWeek);
    setCurrentMonth(data.northStarMetric?.currentMonth);
    setPastMonth(data.northStarMetric?.pastMonth);
  };

  const renderCard = (
    number: string | number | undefined,
    title: string,
    subtitle: string,
    status: 'success' | 'error' | 'none' = 'none'
  ) => (
    <div
      style={{
        ...styles.card,
        ...(status === 'success' && styles.green),
        ...(status === 'error' && styles.red),
      }}
    >
      <div style={styles.cardNumber}>{number || 0}</div>
      <div style={styles.cardTitle}>{title}</div>
      <div style={styles.cardNumberSubtitle}>{subtitle}</div>
    </div>
  );

  if (!canView) {
    return null;
  }

  return (
    <Card>
      <CardHeader avatar="🌟" title="Users who've taken action" />
      <CardContent>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
          }}
        >
          {renderCard(today, 'Today', `${yesterday || 0} yesterday`)}
          {renderCard(currentWeek, 'This week', `${pastWeek || 0} last week`)}
          {renderCard(currentMonth, 'This month', `${pastMonth || 0} last month`)}
        </div>
      </CardContent>
    </Card>
  );
};

const styles: any = {
  card: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
    margin: 20,
    borderRadius: 8,
    backgroundColor: Colors.Background.light,
    border: `1px solid ${Colors.Grey[100]}`,
  },
  cardNumber: {
    fontSize: 40,
    lineHeight: '1.2em',
    fontWeight: 600,
  },
  cardNumberSubtitle: {
    fontSize: 12,
    fontWeight: 600,
    color: Colors.Grey[700],
  },
  cardTitle: {
    margin: '10px 0',
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1.2,
    color: Colors.Grey.primary,
    textTransform: 'uppercase',
    textAlign: 'center',
  },
};

export default NorthStarMetricCard;
