import * as React from 'react';
import {
  DateField,
  FunctionField,
  ReferenceField,
  Show,
  SimpleShowLayout,
} from 'react-admin';
import ReactMarkdown from 'react-markdown';

import ResourceTitleActionBar from '@components/resource_title_action_bar';

const ShowActions = <ResourceTitleActionBar mode="show" />;

export const CausesPostsCommentShow = () => (
  <Show actions={ShowActions}>
    <SimpleShowLayout>
      <DateField
        source="createdAt"
        showTime
        label={`Posted at (${Intl.DateTimeFormat().resolvedOptions().timeZone})`}
      />

      <ReferenceField source="authorId" reference="users" link="show" />
      <FunctionField
        label="Message"
        render={(record: any) => <ReactMarkdown>{record.message}</ReactMarkdown>}
      />
    </SimpleShowLayout>
  </Show>
);
