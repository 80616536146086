import React, { useCallback, useMemo, useState } from 'react';
import { CampaignType } from 'types/campaign';
import { compressedImageUrl } from 'src/modules/cloudinary';

import CauseChip from '@models/causes/components/chip';
import { FontStyle } from '@styles/variables';

type CampaignBlockProps = {
  campaign?: CampaignType;
  clickable?: boolean | string;
  withCauses?: boolean;
  style?: any;
  label?: string;
  size?: 'small' | 'medium' | 'large';
};
const CampaignBlock = ({
  campaign,
  clickable = false,
  withCauses = false,
  style,
  size = 'medium',
}: CampaignBlockProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const imageUrl = useMemo(() => {
    if (!campaign) {
      return;
    }
    return compressedImageUrl(campaign.imageUrl, { width: 100 });
  }, [campaign]);

  const handleOnClick = useCallback(() => {
    if (!clickable || !campaign) {
      return;
    }
    if (typeof clickable === 'string') {
      window.location.href = clickable;
      return;
    }
    window.location.href = `#/campaigns/${campaign.id}/show`;
  }, [clickable, campaign]);

  if (!campaign) {
    return <></>;
  }

  return (
    <a
      style={{
        ...StyleSheet.container,
        cursor: clickable ? 'pointer' : 'inherit',
        ...style,
      }}
      onClick={handleOnClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img
        style={{
          ...StyleSheet.image,
          ...(size === 'small' ? StyleSheet.imageSmall : {}),
        }}
        src={imageUrl}
      />
      <div>
        <div
          style={{
            ...StyleSheet.name,
            ...(size === 'small' ? StyleSheet.nameSmall : {}),
            ...(isHovered && clickable ? StyleSheet.containerHovered : undefined),
          }}
        >
          {campaign.name}
        </div>
        {withCauses &&
          campaign.causes?.map(cause => <CauseChip key={cause.id} cause={cause} />)}
      </div>
    </a>
  );
};

const StyleSheet: any = {
  container: {
    display: 'inline-flex',
    gap: 12,
    alignItems: 'center',
    textDecoration: 'none',
  },
  containerHovered: {
    textDecoration: 'underline',
  },
  image: {
    maxWidth: 50,
    maxHeight: 50,
    aspectRatio: '1',
    borderRadius: 100,
    boxShadow: '0px 0px 0 1px rgba(0,0,0,.05)',
  },
  imageSmall: {
    maxWidth: 25,
    maxHeight: 25,
  },
  name: {
    fontSize: FontStyle.sizeSmall,
    fontWeight: 600,
    color: 'black',
  },
  nameSmall: {
    fontSize: FontStyle.sizeVerySmall,
  },
};

export default CampaignBlock;
