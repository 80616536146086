import React, { useMemo } from 'react';
import { useRecordContext } from 'react-admin';
import { Card, CardContent, CardHeader } from '@mui/material';
import PriorityIcon from '@mui/icons-material/LocalFireDepartment';
import { ActionType } from 'types/action';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import { UpdateFieldDialog } from '@components/update_field_dialog';
import TodoChecklist from '@components/todo_checklist';
import Callout from '@components/callout';
import { priorityValues } from '@models/actions/components/priority';
import { SpacingStyle } from '@styles/variables';

const PriorityCard = () => {
  const action: ActionType = useRecordContext();
  const {
    canUpdate: canUpdateFromRBP,
    isSuperAdmin,
    isCampaignAdmin,
  } = useRoleBasedPermissions();
  const canEdit: boolean = useMemo(
    () => canUpdateFromRBP('actions') && (isSuperAdmin || isCampaignAdmin),
    [canUpdateFromRBP, isSuperAdmin, isCampaignAdmin]
  );

  return (
    <Card>
      <CardHeader
        avatar={<PriorityIcon />}
        title="Priority"
        subheader="How urgent is this action?"
      />
      <CardContent>
        <Callout emoji="👇" backgroundColor="grey">
          The higher the priority, the more urgent the action is and the more chance it
          has to be selected by the system to be presented to the user.
          {!canEdit && (
            <div>
              <br />
              This setting is only available to admins, please reach out!
            </div>
          )}
        </Callout>
        <TodoChecklist
          checked={!!action?.points}
          label="How urgent is this action?"
          cta={
            canEdit && (
              <UpdateFieldDialog
                resource="actions"
                record={action}
                type="select"
                field="priority"
                buttonLabel="Edit"
                buttonVariant="outlined"
                selectOptions={priorityValues}
              />
            )
          }
        >
          <div
            style={{
              marginTop: SpacingStyle.normal,
              marginBottom: SpacingStyle.normal,
              fontWeight: 600,
              lineHeight: 1,
            }}
          >
            {priorityValues.find(p => p.id === action?.priority)?.name || 'default'}
          </div>
        </TodoChecklist>
      </CardContent>
    </Card>
  );
};

export default PriorityCard;
