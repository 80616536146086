import React, { useState, useCallback, useMemo } from 'react';
import {
  useRecordContext,
  useDataProvider,
  useRefresh,
  Button,
  SimpleForm,
} from 'react-admin';
import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import PublicIcon from '@mui/icons-material/Public';
import { ActionType } from 'types/action.d';
import { getCountryData, getEmojiFlag, TCountryCode } from 'countries-list';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import { getStateAbbreviation } from '@services/regions';
import CountryInput from '@components/inputs/country_input';
import RegionInput from '@components/inputs/region_input';
import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';

const AvailableCountriesCard = () => {
  const action: ActionType = useRecordContext();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const { canUpdate: canUpdateFromRPB } = useRoleBasedPermissions();
  const canEdit: boolean = useMemo(() => canUpdateFromRPB('actions'), [canUpdateFromRPB]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState<string[] | undefined | null>(
    action.availableCountries
  );
  const [selectedRegions, setSelectedRegions] = useState<string[] | undefined | null>(
    action.availableRegions
  );
  const [updating, setUpdating] = useState(false);

  const isActionAvailableGlobally = useMemo(
    () => selectedCountries === null || selectedCountries?.length === 0,
    [selectedCountries]
  );

  const isActionAvailableInUS = useMemo(
    () => selectedCountries?.includes('US'),
    [selectedCountries]
  );

  const handleOpen = () => setDialogOpen(true);
  const handleClose = () => {
    setDialogOpen(false);
    setSelectedCountries(action.availableCountries);
    setSelectedRegions(action.availableRegions);
  };

  /**
   * Update the available countries and regions for the action
   */
  const handleUpdate = useCallback(async () => {
    setUpdating(true);
    try {
      await dataProvider.update('actions', {
        id: action.id,
        data: {
          availableCountries: selectedCountries,
          availableRegions: isActionAvailableInUS ? selectedRegions : null,
        },
      } as any);
      refresh();
      setDialogOpen(false);
    } catch (error) {
      console.error('[AvailableCountriesCard.handleUpdate]', error);
    } finally {
      setUpdating(false);
    }
  }, [
    action.id,
    dataProvider,
    refresh,
    selectedCountries,
    selectedRegions,
    isActionAvailableInUS,
  ]);

  return (
    <Card>
      <CardHeader
        avatar={<PublicIcon />}
        title="Target's location"
        subheader="Manage where this action will be available"
        action={
          canEdit && (
            <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
              <Button
                onClick={handleOpen}
                label={selectedCountries ? 'Update availability' : 'Add availability'}
                variant="outlined"
                disabled={updating}
              />
            </Stack>
          )
        }
      />
      <CardContent>
        <div
          style={{
            border: `1px solid ${Colors.Magenta[100]}`,
            padding: SpacingStyle[12],
            borderRadius: BorderStyle.Radius.small,
            backgroundColor: Colors.Magenta[50],
            fontSize: FontStyle.sizeMedium,
            textAlign: 'center',
            fontWeight: 500,
          }}
        >
          {isActionAvailableGlobally ? (
            'You need to select at least one country'
          ) : (
            <Stack
              direction="row"
              spacing={1}
              flexWrap="wrap"
              alignContent={'center'}
              justifyContent={'center'}
              sx={{ flexWrap: 'wrap' }}
              useFlexGap
            >
              {action.availableCountries?.map(country => (
                <Chip
                  key={country}
                  label={`${getEmojiFlag(country as TCountryCode)} ${
                    getCountryData(country as TCountryCode).name
                  }`}
                />
              ))}
              {isActionAvailableInUS &&
                selectedRegions?.map(state => (
                  <Chip key={state} label={`${state} (${getStateAbbreviation(state)})`} />
                ))}
            </Stack>
          )}
        </div>

        <Dialog open={dialogOpen} onClose={handleClose}>
          <DialogTitle>Update target location</DialogTitle>
          <DialogContent>
            <SimpleForm
              defaultValues={{
                availableCountries: action.availableCountries,
                availableRegions: action.availableRegions,
              }}
              toolbar={false}
            >
              <CountryInput
                source="availableCountries"
                label="Countries"
                multiple={true}
                fullWidth
                helperText="You can select multiple countries."
                emptyText="Globally available"
                disabled={updating}
                onChange={(event: any) => setSelectedCountries(event)}
              />
              {isActionAvailableInUS && (
                <RegionInput
                  source="availableRegions"
                  label="Regions (US)"
                  multiple={true}
                  fullWidth
                  helperText="You can select multiple US states."
                  emptyText="All US states available"
                  disabled={updating}
                  onChange={(event: any) => setSelectedRegions(event)}
                />
              )}
            </SimpleForm>
          </DialogContent>
          <DialogActions>
            <Button label="Cancel" onClick={handleClose} disabled={updating} />
            <Button label="Update" onClick={handleUpdate} disabled={updating} />
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default AvailableCountriesCard;
