import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CausePostType } from 'types/cause_post';
import { CauseType } from 'types/cause';
import { useDataProvider, useRedirect } from 'react-admin';

import CauseChip from '@models/causes/components/chip';
import { Colors, FontStyle, SpacingStyle } from '@styles/variables';

type PreviewProps = {
  record: CausePostType;
  imageText?: string;
  locale?: 'en' | 'fr';
  editable?: boolean;
  size?: 'small' | 'normal';
  style?: React.CSSProperties;
  withCause?: boolean;
};

const CausePostPreview = ({
  record,
  imageText,
  locale = 'en',
  size = 'normal',
  editable = false,
  withCause = true,
  style,
}: PreviewProps) => {
  const { medias } = record;

  const dataProvider = useDataProvider();
  const redirect = useRedirect();

  const [cause, setCause] = useState<CauseType | null>(null);

  const titleField = useMemo(() => (locale === 'en' ? 'titleEN' : 'titleFR'), [locale]);
  const contentField = useMemo(
    () => (locale === 'en' ? 'contentEN' : 'contentFR'),
    [locale]
  );

  const fetchCause = useCallback(async () => {
    if (!record?.causeId) {
      return;
    }
    const { data } = await dataProvider.getOne('causes', { id: record.causeId });
    setCause(data);
  }, [record?.causeId, dataProvider]);

  const onImageClickHandler = useCallback(() => {
    if (!record?.id || !editable) {
      return;
    }
    redirect(`/causes_posts/${record.id}`);
  }, [editable, record?.id, redirect]);

  type ReactionsType = {
    [label: string]: number;
  };
  const reactions = useMemo(() => {
    if (!record?.comment?.reactions) {
      return {};
    }
    const reactions = record.comment.reactions.reduce((acc, reaction) => {
      if (!acc[reaction.label]) {
        acc[reaction.label] = 1;
      } else {
        acc[reaction.label] += 1;
      }
      return acc;
    }, {} as ReactionsType);
    return Object.keys(reactions)
      .sort((a, b) => reactions[b] - reactions[a])
      .reduce((acc, key) => {
        acc[key] = reactions[key];
        return acc;
      }, {} as ReactionsType);
  }, [record?.comment?.reactions]);

  useEffect(() => {
    fetchCause();
  }, [fetchCause]);

  return (
    <div
      style={{
        ...styles.container,
        ...(editable ? { cursor: 'pointer' } : null),
        ...style,
      }}
      onClick={onImageClickHandler}
    >
      {withCause && <CauseChip cause={cause} />}
      <div style={styles.postContainer}>
        <div style={styles.tagsContainer}>
          {!record?.publishedAt ? <div style={{ ...styles.tag }}>Draft</div> : <div />}
          {<div style={{ ...styles.tag }}>{locale.toUpperCase()}</div>}
        </div>
        <div
          style={{
            ...styles.imageContainer,
            ...(size === 'small' ? styles.imageContainerSmall : null),
          }}
        >
          {medias?.length && medias[0].imageUrl && (
            <img src={medias[0].imageUrl} style={styles.image} />
          )}
          {imageText && <div style={styles.imageText}>{imageText}</div>}
        </div>
        <div style={styles.textContainer}>
          <div
            style={{ ...styles.title, ...(size === 'small' ? styles.titleSmall : null) }}
          >
            {record[titleField]}
          </div>
          {size !== 'small' && <div style={styles.content}>{record[contentField]}</div>}

          {reactions && Object.keys(reactions).length > 0 && (
            <div style={styles.reactions}>
              {Object.keys(reactions).map(label => (
                <div key={label} style={styles.reaction}>
                  {label} {reactions[label]}
                </div>
              ))}
            </div>
          )}
          <div
            style={styles.replies}
            onClick={e => {
              e.stopPropagation();
              window.location.href = `#/causes_posts/${record.id}/chat`;
            }}
          >
            {record.comment?.repliesCount || 0} replies
          </div>
        </div>
      </div>
    </div>
  );
};

const styles: any = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 400,
    width: '100%',
    padding: SpacingStyle.normal,
    borderRadius: SpacingStyle.small,
    backgroundColor: Colors.White.primary,
    boxShadow: '0px 2px 18px rgba(0, 0, 0, 0.05)',
    overflow: 'hidden',
    gap: SpacingStyle.small,
  },
  postContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: SpacingStyle.normal,
  },
  tagsContainer: {
    position: 'absolute',
    top: SpacingStyle.small,
    left: SpacingStyle.small,
    right: SpacingStyle.small,
    display: 'flex',
    justifyContent: 'space-between',
    gap: SpacingStyle.small,
  },
  tag: {
    padding: '2px 4px',
    borderRadius: SpacingStyle.small,
    backgroundColor: Colors.White.transparent.light,
    color: Colors.Black.transparent.light,
    fontSize: FontStyle.sizeVerySmall,
    fontWeight: '800',
    backdropFilter: 'blur(5px)',
  },
  imageContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    aspectRatio: '1',
    objectFit: 'cover',
    borderRadius: SpacingStyle.small,
    backgroundColor: Colors.Grey[100],
    background: `linear-gradient(155deg, ${Colors.Blue[100]}, ${Colors.Magenta[200]})`,
    overflow: 'hidden',
  },
  image: {
    aspectRatio: '1',
    maxWidth: '100%',
    opacity: 0.9,
    backgroundColor: Colors.Black.primary,
    objectFit: 'cover',
  },
  imageText: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    padding: SpacingStyle.normal,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    color: Colors.White.primary,
    fontWeight: '600',
    fontSize: FontStyle.sizeVeryBig,
    background: `linear-gradient(transparent, ${Colors.Black.transparent.light})`,
  },
  imageContainerSmall: {
    width: '100%',
    maxHeight: 120,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: SpacingStyle.small,
  },
  title: {
    fontSize: FontStyle.sizeMedium,
    lineHeight: 1.2,
    fontWeight: '600',
  },
  titleSmall: {
    fontSize: FontStyle.sizeSmall,
  },
  content: {
    fontSize: FontStyle.sizeSmall,
    fontWeight: '400 !important',
    textAlign: 'left',
    whiteSpace: 'pre-line',
  },
  reactions: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: SpacingStyle.small,
    marginTop: SpacingStyle[4],
    marginBottom: SpacingStyle[4],
  },
  reaction: {
    padding: '0px 4px',
    borderRadius: SpacingStyle[112],
    border: `1px solid ${Colors.Black.transparent.max}`,
    fontSize: FontStyle.sizeVeryVerySmall,
    fontWeight: '600',
    color: Colors.Grey[700],
  },
  replies: {
    fontSize: FontStyle.sizeVerySmall,
    color: Colors.Grey[500],
  },
};

export default CausePostPreview;
